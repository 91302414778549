/**
 * @fileoverview Form component for creating and editing crosswords
 * Provides interface for setting crossword name and duration
 */

import React from 'react';
import { useState } from 'react';

/**
 * Crossword form component for creation and editing
 * Features:
 * - Name input with character limit
 * - Duration slider with minute conversion
 * - Validation messaging
 * - Create/Update/Cancel actions
 *
 * @param {Object} props - Component props
 * @param {Object} [props.crossword] - Existing crossword data for editing
 * @param {string} [props.crossword.name] - Crossword name
 * @param {string} [props.crossword.duration] - Crossword duration in seconds
 * @param {Function} props.onSubmit - Callback when form is submitted
 * @param {Function} props.onCancel - Callback when form is cancelled
 * @returns {JSX.Element} Rendered form component
 */
const CrosswordForm = ({ crossword: crosswordData, onSubmit, onCancel }) => {
  const [validationMessage, setValidationMessage] = useState('');
  const [crossword, setCrossword] = useState(
    crosswordData || {
      name: '',
      duration: '600',
    }
  );

  /**
   * Handles name input changes with character limit validation
   * @param {React.ChangeEvent<HTMLInputElement>} e - Change event
   */
  const handleNameChange = (e) => {
    if (e.target.value.length <= 35) {
      setCrossword({
        ...crossword,
        name: e.target.value,
      });
    } else {
      e.target.value = e.target.value.slice(0, 35);
    }
  };

  /**
   * Handles duration slider changes
   * @param {React.ChangeEvent<HTMLInputElement>} e - Change event
   */
  const handleDurationChange = (e) => {
    setCrossword({
      ...crossword,
      duration: e.target.value,
    });
  };

  /**
   * Handles form submission with validation
   * @param {React.FormEvent<HTMLFormElement>} e - Form submit event
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (crossword.name === '') {
      setValidationMessage('Crossword Name cannot be empty');
    } else {
      onSubmit(crossword);
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__control">
        <div className="input-group">
          <input
            type="text"
            name="name"
            placeholder=""
            defaultValue={crossword.name}
            onChange={handleNameChange}
            onFocus={() => {
              setValidationMessage('');
            }}
          />
          <label>Crowssword Name</label>
          <span className="character-count">{crossword.name.length} / 35</span>
        </div>
        <div className="range-group">
          <span className="duration-label">
            Duration:{' '}
            {crossword.duration < 600
              ? `0${crossword.duration / 60}`
              : crossword.duration / 60}{' '}
            min
          </span>
          <input
            type="range"
            min={'300'}
            max={'3600'}
            step={300}
            defaultValue={crossword.duration}
            onChange={handleDurationChange}
          />
        </div>
      </div>
      <div className="form-actions">
        <button className="btn--primary" type="submit">
          {crosswordData ? 'Update' : 'Create'}
        </button>
        <button className="btn--secondary" onClick={onCancel}>
          Cancel
        </button>
      </div>
      <p className="validation-message">{validationMessage}</p>
    </form>
  );
};

export default CrosswordForm;
