/**
 * @fileoverview Skeleton loading component for question items
 * Provides a loading placeholder that matches the structure of question components
 */

import React from 'react';

/**
 * Question item skeleton component for loading states
 * Displays a placeholder with animated gradients that match the question layout:
 * - Question text placeholder
 * - Four option placeholders
 * - Answer placeholder
 * - Action button placeholders
 *
 * Used during:
 * - Initial question loading
 * - Question updates
 * - Question list loading
 *
 * @returns {JSX.Element} Rendered skeleton loader for question items
 */
const QuestionItemSkeleton = () => {
  return (
    <>
      <div className="question-item__container">
        <div className="question-item__content">
          <div className="h4__skeleton"></div>
          <div className="question-item__options">
            <div className="text__skeleton"></div>
            <div className="text__skeleton"></div>
            <div className="text__skeleton"></div>
            <div className="text__skeleton"></div>
          </div>
          <div className="question-item__answer">
            <div className="text__skeleton"></div>
          </div>
        </div>
        <div className="question-item__controls">
          <button className="btn__skeleton--primary"></button>
          <button className="btn__skeleton--secondary"></button>
        </div>
      </div>
    </>
  );
};

export default QuestionItemSkeleton;
