/**
 * @fileoverview Word input component for crossword creation
 * Provides text area for entering word-clue pairs with validation
 */

import React, { useState } from 'react';

import { canBuildCrossword } from '../../utils/crossword.js';

/**
 * Word input component for managing crossword words and clues
 * Features:
 * - Text area for entering word-clue pairs
 * - Format validation (word: clue)
 * - Character validation
 * - Crossword buildability check
 * - Error messaging
 *
 * @param {Object} props - Component props
 * @param {Array<{answer: string, clue: string}>} [props.words] - Initial words array
 * @param {Function} props.onUpdate - Callback when words are successfully updated
 * @param {Function} props.onCancel - Callback when input is cancelled
 * @returns {JSX.Element} Rendered word input component
 */
const WordBox = ({ words, onUpdate, onCancel }) => {
  const [validationMessage, setValidationMessage] = useState('');
  const [text, setText] = useState(
    words ? words.map((word) => `${word.answer}: ${word.clue}`).join('\n') : ''
  );

  /**
   * Handles text input changes with character validation
   * @param {React.ChangeEvent<HTMLTextAreaElement>} e - Change event
   */
  const handleTextChange = (e) => {
    setValidationMessage('');
    const regex = /^[a-zA-Z0-9:,.\s\n]*$/;
    if (regex.test(e.target.value)) {
      setText(e.target.value);
    } else {
      setValidationMessage(
        'can only use alphabets, numbers, and following special characters: `:` `,` `.`'
      );
    }
  };

  /**
   * Processes and validates the entered text
   * Checks for:
   * - Non-empty input
   * - Correct format (word: clue)
   * - Valid characters
   * - Crossword buildability
   */
  const handleWordsSubmit = () => {
    if (!text || text == '') {
      setValidationMessage('Cannot be empty');
    } else {
      const regex = /^(([a-zA-Z\s]*:[a-zA-Z0-9\s.,]*(?:\n+|$))+)?$/;

      if (regex.test(text)) {
        const answerList = [];
        const wordList = [];
        const lines = text.trim().replace(/\n+/g, '\n');
        lines.split('\n').forEach((line) => {
          line = line.trim().replace(/\n+/g, '\n');
          const word = line.split(':');
          answerList.push(word[0]);
          wordList.push({
            answer: word[0].trim().replace(/\s+/g, ''),
            clue: word[1].trim().replace(/\s+/g, ' '),
          });
        });

        if (canBuildCrossword(answerList)) {
          onUpdate(wordList);
        } else {
          setValidationMessage(
            'The given set of words cannot form a crossword!'
          );
        }
      } else {
        setValidationMessage('Please enter data in proper format');
      }
    }
  };

  return (
    <div className="word-form__container">
      <div className="word-form__content">
        <p className="validation-message">{validationMessage}</p>
        <textarea
          className="word-form__textarea"
          value={text}
          onChange={handleTextChange}
          placeholder={'ANSWER1: Clue1\nANSWER2: Clue2'}
          rows={10}
          onFocus={() => setValidationMessage('')}
        ></textarea>
        <br />
      </div>
      <div className="word-form__controls">
        <button className="btn--primary" onClick={handleWordsSubmit}>
          Submit
        </button>
        <button
          className="btn--secondary"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default WordBox;
