/**
 * @fileoverview Crossword submissions listing page
 * Displays all submissions for a specific crossword
 */

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Loading from '../../components/UI/Loading';

import { getCrossword, getSubmissions } from '../../redux/actions/crosswords';

/**
 * Crossword submissions listing component
 * Features:
 * - Automatic data fetching on mount
 * - Tabular display of submissions
 * - Loading state handling
 * - Empty state messaging
 *
 * @param {Object} props - Component props
 * @param {Array<Object>} props.crosswords - Array of crossword objects
 * @param {Function} props.getCrossword - Action to fetch crossword details
 * @param {Function} props.getSubmissions - Action to fetch crossword submissions
 * @param {boolean} props.loading - Loading state
 * @returns {JSX.Element} Rendered submissions list
 */
const ListSubmissions = ({
  crosswords,
  getCrossword,
  getSubmissions,
  loading,
}) => {
  const params = useParams();
  const crosswordId = params.id;

  const crossword = crosswords.find(
    (crossword) => crossword.crosswordId === crosswordId
  );

  /**
   * Fetches crossword and submissions data if not already loaded
   */
  useEffect(() => {
    if (!crossword) {
      getCrossword(crosswordId);
      getSubmissions(crosswordId);
    }
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="submissions-container">
      {crossword &&
      crossword.submissions &&
      crossword.submissions.length > 0 ? (
        <>
          <div className="submission-column--name">
            <div className="submission--header">
              <p>Name</p>
            </div>
            {crossword.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.name}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--email">
            <div className="submission--header">
              <p>Email</p>
            </div>
            {crossword.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.email}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--score">
            <div className="submission--header">
              <p>Score</p>
            </div>
            {crossword.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.score}</p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p>No Submissions so far...</p>
      )}
    </div>
  );
};

/**
 * Maps Redux state to component props
 * @param {Object} state - Redux state
 * @returns {Object} Props for component
 */
const mapStateToProps = (state) => {
  return {
    crosswords: state.crosswords.crosswords,
    loading: state.crosswords.loading,
    error: state.crosswords.error,
  };
};

/**
 * Redux action creators to be bound to component props
 * @type {Object.<string, Function>}
 */
const mapDispatchToProps = {
  getCrossword,
  getSubmissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSubmissions);
