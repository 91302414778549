/**
 * @fileoverview Main router component for the application
 * Defines the routing structure and layout for the entire app
 */

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from '../pages/Home.jsx';
import ListQuizzes from '../pages/Quiz/ListQuizzes.jsx';
import EditQuiz from '../pages/Quiz/EditQuiz.jsx';
import Header from '../components/UI/Header.jsx';
import PageNotFound from '../pages/PageNotFound.jsx';
import NavPane from '../components/UI/NavPane.jsx';
import ListQuizSubmissions from '../pages/Quiz/ListSubmissions.jsx';
import ListCrosswordSubmissions from '../pages/Crossword/ListSubmissions.jsx';
import ComingSoon from '../pages/ComingSoon.jsx';
import NotificationToast from '../components/UI/NotificationToast.jsx';
import ListCrosswords from '../pages/Crossword/ListCrosswords.jsx';
import EditCrossword from '../pages/Crossword/EditCrossword.jsx';

/**
 * Main router component that handles application routing and layout
 * Provides the following structure:
 * - Header with authentication info
 * - Navigation pane
 * - Main content area with routes for:
 *   - Home
 *   - Quiz management (list, edit, submissions)
 *   - Crossword management (list, edit, submissions)
 *   - Various placeholder routes for upcoming features
 *
 * @param {Object} params - Component parameters
 * @param {Object} params.auth - Authentication state object
 * @param {string} params.auth.status - Authentication status
 * @param {string} [params.auth.name] - User's name if authenticated
 * @returns {JSX.Element} Rendered router component with layout
 */
const AppRouter = (params) => {
  return (
    <BrowserRouter>
      <div className="root-container">
        <NotificationToast />
        <div className="header-container">
          <Header auth={params.auth} />
        </div>
        <div className="main-container">
          <div className="nav-container">
            <NavPane />
          </div>
          <div className="app-container">
            {/* Application Routes
                Define all available paths and their corresponding components */}
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/quizzes" Component={ListQuizzes} />
              <Route path="/quizzes/:id" Component={EditQuiz} />
              <Route
                path="/quizzes/:id/submissions"
                Component={ListQuizSubmissions}
              />
              <Route path="/crosswords" Component={ListCrosswords} />
              <Route path="/crosswords/:id" Component={EditCrossword} />
              <Route
                path="/crosswords/:id/submissions"
                Component={ListCrosswordSubmissions}
              />
              <Route path="/surveys" Component={ComingSoon} />
              <Route path="/interactive-quizzes" Component={ComingSoon} />
              <Route path="/library" Component={ComingSoon} />
              <Route path="/organisations" Component={ComingSoon} />
              <Route path="/profile" Component={ComingSoon} />
              <Route path="/settings" Component={ComingSoon} />
              <Route path="*" Component={PageNotFound} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default AppRouter;
