/**
 * @fileoverview Entry point for the Gyaankreeda creator web application.
 * This file initializes the React application, sets up Redux store,
 * handles authentication flow, and renders the main application router.
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import AppRouter from './routers/AppRouter';
import './styles/main.scss';
import createStore from './redux/store/initialize.js';
import securityService from './services/security.service.js';
import favIcon from './assets/images/favicon.ico';

/**
 * Creates and appends the root DOM element for React application mounting
 * @type {HTMLDivElement}
 */
const appRoot = document.createElement('div');
appRoot.id = 'root';
document.body.appendChild(appRoot);
const root = ReactDOM.createRoot(appRoot);

/**
 * Redux store instance for state management
 * @type {import('redux').Store}
 */
const store = createStore();

/**
 * Promise that handles the authentication flow
 * Checks if we're in a callback URL from authentication,
 * then either completes the auth process or checks existing session
 * @type {Promise<{status: string}>}
 */
const authPromise = window.location.href.includes('/login/callback')
  ? securityService.completeUserAuth()
  : securityService.checkUserSession();

authPromise
  .then((auth) => {
    const favIconLink = document.createElement('link');
    favIconLink.rel = 'icon';
    favIconLink.type = 'image/ico';
    favIconLink.href = favIcon;
    document.head.appendChild(favIconLink);
    if (auth.status === 'Authenticated') {
      root.render(
        <Provider store={store}>
          <AppRouter auth={auth} />
        </Provider>
      );
    } else {
      return securityService.inititateUserAuth();
    }
  })
  .then((loginUrl) => {
    if (loginUrl) window.location.href = loginUrl;
  })
  .catch((error) => {
    console.log(error);
  });
