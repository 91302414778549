/**
 * @fileoverview Quiz listing page
 * Container component for displaying all quizzes
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import QuizList from '../../components/Quiz/QuizList';

import { getQuizzes } from '../../redux/actions/quizzes.js';
import { clearError } from '../../redux/reducers/quizzes.js';

/**
 * Quiz listing page component
 * Features:
 * - Automatic data fetching on mount
 * - Error clearing on unmount
 * - Renders QuizList component
 *
 * @param {Object} props - Component props
 * @param {Array<Object>} props.quizzes - Array of quiz objects
 * @param {Function} props.getQuizzes - Action to fetch all quizzes
 * @param {Function} props.clearError - Action to clear error state
 * @returns {JSX.Element} Rendered quiz list
 */
const ListQuizzes = ({ quizzes, getQuizzes, clearError }) => {
  /**
   * Fetches quizzes on mount if not already loaded
   * Clears error state on unmount
   */
  useEffect(() => {
    if (quizzes.length <= 1) getQuizzes();
    return () => {
      clearError();
    };
  }, []);
  return <QuizList />;
};

/**
 * Maps Redux state to component props
 * @param {Object} state - Redux state
 * @returns {Object} Props for component
 */
const mapStateToProps = (state) => ({
  quizzes: state.quizzes.quizzes,
  loading: state.quizzes.loading,
  error: state.quizzes.error,
  errorDetail: state.quizzes.errorDetail,
});

/**
 * Redux action creators to be bound to component props
 * @type {Object.<string, Function>}
 */
const mapDispatchToProps = {
  getQuizzes,
  clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListQuizzes);
