/**
 * @fileoverview Question form component for quiz questions
 * Provides interface for creating and editing quiz questions with multiple choice options
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';

/**
 * Question form component with multiple choice options
 * Features:
 * - Question text with character limit
 * - Four options with character limits
 * - Answer selection via radio buttons
 * - Validation for all fields
 * - Create/Update/Cancel actions
 *
 * @param {Object} props - Component props
 * @param {Object} props.question - Question data
 * @param {string} props.question.text - Question text
 * @param {string} props.question.optionA - First option
 * @param {string} props.question.optionB - Second option
 * @param {string} props.question.optionC - Third option
 * @param {string} props.question.optionD - Fourth option
 * @param {string} props.question.answer - Correct answer (A/B/C/D)
 * @param {string} props.action - Form mode ('create' or 'update')
 * @param {Function} props.onAdd - Callback when question is added/updated
 * @param {Function} [props.onCancel] - Callback when form is cancelled
 * @returns {JSX.Element} Rendered form component
 */
const QuestionForm = ({ question: questionData, action, onAdd, onCancel }) => {
  const [validationMessage, setValidationMessage] = useState('');
  const [question, setQuestion] = useState(questionData);

  /**
   * Handles question text changes with character limit
   * @param {React.ChangeEvent<HTMLTextAreaElement>} e - Change event
   */
  const handleTextChange = (e) => {
    if (e.target.value.length <= 350) {
      setQuestion({ ...question, text: e.target.value });
    } else {
      e.target.value = e.target.value.slice(0, 350);
    }
  };

  /**
   * Handles option A changes with character limit
   * @param {React.ChangeEvent<HTMLInputElement>} e - Change event
   */
  const handleOptionAChange = (e) => {
    if (e.target.value.length <= 100) {
      setQuestion({ ...question, optionA: e.target.value });
    } else {
      e.target.value = e.target.value.slice(0, 100);
    }
  };

  /**
   * Handles option B changes with character limit
   * @param {React.ChangeEvent<HTMLInputElement>} e - Change event
   */
  const handleOptionBChange = (e) => {
    if (e.target.value.length <= 100) {
      setQuestion({ ...question, optionB: e.target.value });
    } else {
      e.target.value = e.target.value.slice(0, 100);
    }
  };

  /**
   * Handles option C changes with character limit
   * @param {React.ChangeEvent<HTMLInputElement>} e - Change event
   */
  const handleOptionCChange = (e) => {
    if (e.target.value.length <= 100) {
      setQuestion({ ...question, optionC: e.target.value });
    } else {
      e.target.value = e.target.value.slice(0, 100);
    }
  };

  /**
   * Handles option D changes with character limit
   * @param {React.ChangeEvent<HTMLInputElement>} e - Change event
   */
  const handleOptionDChange = (e) => {
    if (e.target.value.length <= 100) {
      setQuestion({ ...question, optionD: e.target.value });
    } else {
      e.target.value = e.target.value.slice(0, 100);
    }
  };

  /**
   * Handles answer selection change
   * @param {React.MouseEvent<SVGElement>} e - Click event on icon
   */
  const handleAnswerChange = (e) => {
    setQuestion({ ...question, answer: e.target.id });
  };

  return (
    <div id={action}>
      <div className="question-form__container">
        <div className="question-form__content">
          <div className="question-form__text">
            <textarea
              className="question-form__text-area"
              placeholder="Question"
              value={question.text}
              onChange={handleTextChange}
              onFocus={() => {
                setValidationMessage('');
              }}
            />
            <label className="character-limit">
              {question.text.length} / 350
            </label>
          </div>
          <div className="question-form__options">
            <div className="question-form__option">
              <input
                type="text"
                placeholder="Option A"
                value={question.optionA}
                onChange={handleOptionAChange}
                onFocus={() => {
                  setValidationMessage('');
                }}
              />
              <FontAwesomeIcon
                id="A"
                icon={question.answer === 'A' ? faCircleCheck : faCircle}
                className="faQuestionOption"
                onClick={handleAnswerChange}
              />
            </div>
            <div className="question-form__option">
              <input
                type="text"
                placeholder="Option B"
                value={question.optionB}
                onChange={handleOptionBChange}
                onFocus={() => {
                  setValidationMessage('');
                }}
              />
              <FontAwesomeIcon
                id="B"
                icon={question.answer === 'B' ? faCircleCheck : faCircle}
                className="faQuestionOption"
                onClick={handleAnswerChange}
              />
            </div>
            <div className="question-form__option">
              <input
                type="text"
                placeholder="Option C"
                value={question.optionC}
                onChange={handleOptionCChange}
                onFocus={() => {
                  setValidationMessage('');
                }}
              />
              <FontAwesomeIcon
                id="C"
                icon={question.answer === 'C' ? faCircleCheck : faCircle}
                className="faQuestionOption"
                onClick={handleAnswerChange}
              />
            </div>
            <div className="question-form__option">
              <input
                type="text"
                placeholder="Option D"
                value={question.optionD}
                onChange={handleOptionDChange}
                onFocus={() => {
                  setValidationMessage('');
                }}
              />
              <FontAwesomeIcon
                id="D"
                icon={question.answer === 'D' ? faCircleCheck : faCircle}
                className="faQuestionOption"
                onClick={handleAnswerChange}
              />
            </div>
          </div>
          <div className="question-form__controls">
            <button
              className="btn--primary"
              onClick={() => {
                if (
                  question.text === '' ||
                  question.optionA === '' ||
                  question.optionB === '' ||
                  question.optionC === '' ||
                  question.optionD === ''
                ) {
                  setValidationMessage('Cannot leave empty');
                } else if (question.answer === '') {
                  setValidationMessage('Please select a correct option');
                } else {
                  onAdd(question);
                  setQuestion({
                    ...questionData,
                  });
                  setValidationMessage('');
                }
              }}
            >
              {questionData.questionId ? 'Update' : 'Add'}
            </button>
            {action !== 'create' && (
              <button
                className="btn--secondary"
                onClick={() => {
                  onCancel();
                  setQuestion({ ...questionData });
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
        <p className="validation-message">{validationMessage}</p>
      </div>
    </div>
  );
};

export default connect()(QuestionForm);
