/**
 * @fileoverview A reusable card component that provides consistent styling and layout
 */

import React from 'react';

/**
 * Card component that wraps content in a styled container
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Content to be rendered inside the card
 * @param {string} [props.className] - Additional CSS classes to apply to the card
 * @returns {JSX.Element} Rendered card component
 */
const Card = ({ children, className }) => {
  return <div className={`card-item ${className || ''}`}>{children}</div>;
};

export default Card;
