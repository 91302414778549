/**
 * @fileoverview Service for managing application configuration
 * Loads and provides access to application-wide configuration settings
 */

/**
 * Fetches and processes the main application configuration
 * @type {Promise<Object>}
 * @property {string} prefixUrl - Base URL for API requests
 * @property {string} redirectUri - Encoded URI for OAuth redirects
 * @property {string} playServer - URL for the play server
 */
const appConfig = fetch('/config/app-config.json', {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
})
  .then((res) => res.json())
  .then((res) => ({
    prefixUrl: res.apiServerUri,
    redirectUri: encodeURIComponent(res.redirectUri),
    playServer: res.playServer,
  }));

/**
 * REST configuration derived from app config
 * @type {Promise<Object>}
 * @property {string} prefixUrl - Base URL for API requests
 * @property {string} credentials - Credentials mode for fetch requests
 */
const restConfig = appConfig.then((appConfig) => ({
  prefixUrl: appConfig.prefixUrl,
  credentials: 'include',
}));

/**
 * Configuration service providing access to app and REST configs
 * @type {Object}
 * @property {Promise<Object>} appConfig - Application configuration
 * @property {Promise<Object>} restConfig - REST API configuration
 */
const configService = { appConfig, restConfig };
export { configService };
