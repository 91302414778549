/**
 * @fileoverview Redux actions for quiz management
 * Contains async thunks for CRUD operations on quizzes and their questions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';

import quizService from '../../services/quiz.service.js';

/**
 * Fetches all quizzes
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quizzes: Array<Quiz>}, void, {}>}
 */
export const getQuizzes = createAsyncThunk(
  'quizzes/get',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const quizzes = await quizService.getQuizzes();
      return fulfillWithValue({ quizzes: [...quizzes] });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Creates a new quiz
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quiz: Quiz}, Object, {}>}
 */
export const createQuiz = createAsyncThunk(
  'quizzes/create',
  async (quiz, { fulfillWithValue, rejectWithValue }) => {
    try {
      const createdQuiz = await quizService.createQuiz(quiz);
      return fulfillWithValue({ quiz: { ...createdQuiz } });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Fetches a single quiz by ID
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quiz: Quiz}, string, {}>}
 */
export const getQuiz = createAsyncThunk(
  'quizzes/getOne',
  async (quizId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const quiz = await quizService.getQuizDetails(quizId);
      return fulfillWithValue({ quiz });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Updates an existing quiz
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quiz: Quiz}, {quizId: string, data: Object}, {}>}
 */
export const updateQuiz = createAsyncThunk(
  'quizzes/update',
  async ({ quizId, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const quiz = await quizService.updateQuiz(quizId, data);
      return fulfillWithValue(quiz);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Deletes a quiz
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quizId: string}, string, {}>}
 */
export const deleteQuiz = createAsyncThunk(
  'quizzes/delete',
  async (quizId, { fulfillWithValue, rejectWithValue }) => {
    try {
      await quizService.deleteQuiz(quizId);
      return fulfillWithValue({ quizId });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Updates the status of a quiz (e.g., publish/unpublish)
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quiz: Quiz}, {quizId: string, status: string}, {}>}
 */
export const updateQuizStatus = createAsyncThunk(
  'quizzes/publish',
  async ({ quizId, status }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const quiz = await quizService.updateQuizStatus(quizId, status);
      return fulfillWithValue(quiz);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Adds a new question to a quiz
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quizId: string, question: Question}, {quizId: string, question: Question}, {}>}
 */
export const addQuestion = createAsyncThunk(
  'quizzes/addQuestion',
  async ({ quizId, question }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const data = await quizService.addQuestion(quizId, question);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Updates an existing question in a quiz
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quizId: string, question: Question}, {quizId: string, question: Question}, {}>}
 */
export const updateQuestion = createAsyncThunk(
  'quizzes/updateQuestion',
  async ({ quizId, question }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const data = await quizService.updateQuestion(quizId, question);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Removes a question from a quiz
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quizId: string, questionId: string}, {quizId: string, questionId: string}, {}>}
 */
export const removeQuestion = createAsyncThunk(
  'quizzes/removeQuestion',
  async ({ quizId, questionId }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const question = await quizService.removeQuestion(quizId, questionId);
      return fulfillWithValue(question);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Fetches all submissions for a quiz
 * @type {import('@reduxjs/toolkit').AsyncThunk<{quizId: string, submissions: Array<Submission>}, string, {}>}
 */
export const getSubmissions = createAsyncThunk(
  'quizzes/getSubmissions',
  async (quizId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const submissions = await quizService.getSubmissions(quizId);
      return fulfillWithValue({ quizId, submissions });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
