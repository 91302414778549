/**
 * @fileoverview Service for handling user authentication and session management
 * Manages the OAuth flow, session checks, and user logout functionality
 */

import { configService } from './config.service.js';

const { prefixUrl, redirectUri } = await configService.appConfig;

/**
 * Checks the current user's authentication status
 * Stores the current URL for post-login redirect
 * @returns {Promise<Object>} Authentication state object
 * @property {string} status - 'Authenticated' or 'Unauthenticated'
 * @property {string} [name] - User's display name (only if authenticated)
 * @property {Function} [logout] - Logout function (only if authenticated)
 */
const checkUserSession = async () => {
  let response;
  // store the original url in the session storage
  let url;
  if (window.location.pathname !== '/') {
    url = window.location.pathname;
  } else {
    url = '/';
  }
  sessionStorage.setItem('original-url', url);

  // Get the auth status from the server
  response = await fetch(`${prefixUrl}/users/me`, { credentials: 'include' });

  const responseBody = await response.json();

  // If the user was successfully authenticated in the server, get the system config and set the auth state
  // (note that at this point the cookie is already set)
  if (responseBody.userInfo) {
    return {
      status: 'Authenticated',
      name:
        responseBody.userInfo.name || responseBody.userInfo.preferred_username,
      logout: () => securityService.logoutUser(),
    };
  } else {
    return {
      status: 'Unauthenticated',
    };
  }
};

/**
 * Initiates the OAuth authorization flow
 * Obtains the auth server's login URL and prepares for redirect
 * @returns {Promise<string>} The URL to redirect the user to for authentication
 * @throws {Error} If the redirect URL is invalid or the request fails
 */
const inititateUserAuth = async () => {
  const response = await fetch(
    `${prefixUrl}/login?redirect_uri=${redirectUri}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );
  const responseBody = await response.json();
  const loginUrl = responseBody.authLocation;
  if (loginUrl) {
    return loginUrl;
  } else {
    throw new Error('Invalid redirection');
  }
};

/**
 * Completes the OAuth flow after auth server callback
 * Processes the auth response and establishes the user session
 * @returns {Promise<Object>} Authentication state object
 * @property {string} status - 'Authenticated' or 'Unauthenticated'
 * @property {string} [name] - User's display name (only if authenticated)
 * @property {Function} [logout] - Logout function (only if authenticated)
 */
const completeUserAuth = async () => {
  let response;
  let auth = {
    status: 'Unauthenticated',
  };

  let searchParamsStr;
  const url = window.location.href.toString();
  const separatorChar = url.indexOf('#') >= 0 ? '#' : '?';
  if (url.includes(separatorChar)) {
    searchParamsStr = url.substring(url.indexOf(separatorChar));
  }
  response = await fetch(
    `${prefixUrl}/login${searchParamsStr}&redirect_uri=${redirectUri}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );

  const responseBody = await response.json();

  // If the user was successfully authenticated in the server, get the system config and set the auth state
  // (note that at this point the cookie is already set)
  if (responseBody.userInfo) {
    // const systemConfigResponse = await configurationSvc.getSystemConfiguration();

    auth = {
      status: 'Authenticated',
      name:
        responseBody.userInfo.name || responseBody.userInfo.preferred_username,
      // systemConfig: systemConfigResponse.body,
      logout: () => securityService.logoutUser(),
    };

    // Restore the original url from the session storage (if any)
    const storedUrl = sessionStorage.getItem('original-url');
    sessionStorage.removeItem('original-url');
    if (storedUrl != null) {
      window.history.replaceState(null, '', storedUrl);
    } else {
      window.history.replaceState(null, '', '/');
    }
  } else {
    auth = {
      status: 'Unauthenticated',
    };
  }

  return auth;
};

/**
 * Logs out the current user
 * Terminates the session and redirects to the login page
 * @returns {Promise<void>}
 */
const logoutUser = async () => {
  const response = await fetch(`${prefixUrl}/logout`, {
    method: 'POST',
    credentials: 'include',
  });
  const data = await response.json();
  window.location.href = data.redirectUrl;
};

const securityService = {
  checkUserSession,
  inititateUserAuth,
  completeUserAuth,
  logoutUser,
};

export default securityService;
