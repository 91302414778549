/**
 * @fileoverview Word item display component for crosswords
 * Displays a word-clue pair in a structured format
 */

import React from 'react';

/**
 * Word item component that displays a crossword word and its clue
 * Provides a consistent layout for word-clue pairs in the crossword interface
 *
 * @param {Object} props - Component props
 * @param {Object} props.word - Word object containing answer and clue
 * @param {string} props.word.answer - The crossword answer word
 * @param {string} props.word.clue - The clue for the answer word
 * @returns {JSX.Element} Rendered word-clue display
 */
const WordItem = ({ word }) => {
  return (
    <div className="word-item__content">
      <div className="word-item__answer">
        <h5>{word.answer}</h5>
      </div>
      <div className="word-item__clue">
        <h5>{word.clue}</h5>
      </div>
    </div>
  );
};

export default WordItem;
