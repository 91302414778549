/**
 * @fileoverview Quiz list component for displaying all quizzes
 * Provides list view with status management and deletion capabilities
 */

import React from 'react';
import { connect } from 'react-redux';

import Loading from '../UI/Loading.jsx';
import QuizListItem from './QuizListItem';

import { updateQuizStatus, deleteQuiz } from '../../redux/actions/quizzes.js';

/**
 * Quiz list component with state management
 * Features:
 * - List of quiz items
 * - Loading and error states
 * - Empty state messaging
 * - Status update handling
 * - Deletion handling
 *
 * @param {Object} props - Component props
 * @param {Array<Object>} props.quizzes - Array of quiz objects
 * @param {Function} props.deleteQuiz - Action to delete a quiz
 * @param {Function} props.updateQuizStatus - Action to update quiz status
 * @param {boolean} props.loading - Loading state
 * @param {boolean} props.error - Error state
 * @param {string} props.errorDetail - Error message
 * @returns {JSX.Element} Rendered list component
 */
const QuizList = ({
  quizzes,
  deleteQuiz,
  updateQuizStatus,
  loading,
  error,
  errorDetail,
}) => {
  // const skeletons = [1, 2, 3];
  /**
   * Handles quiz deletion
   * @param {string} quizId - ID of quiz to delete
   */
  const handleDeleteQuiz = (quizId) => {
    deleteQuiz(quizId);
  };

  /**
   * Handles quiz status updates
   * @param {string} quizId - ID of quiz to update
   * @param {string} status - New status to set
   */
  const handleStatusUpdate = (quizId, status) => {
    updateQuizStatus({ quizId, status });
  };

  return error ? (
    <div>{errorDetail}</div>
  ) : loading ? (
    <Loading />
  ) : quizzes.length < 1 ? (
    <div
      style={{
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <p style={{ textAlign: 'center', color: 'grey' }}>
        Wow, such emptiness. Why don&apos;t you create a new quiz. :)
      </p>
    </div>
  ) : (
    <div className="activity-list">
      {quizzes.map((quiz) => (
        <QuizListItem
          key={quiz.quizId}
          quiz={quiz}
          onDelete={handleDeleteQuiz}
          onStatusUpdate={handleStatusUpdate}
        />
      ))}
    </div>
  );
};

/**
 * Maps Redux state to component props
 * @param {Object} state - Redux state
 * @returns {Object} Props for component
 */
const mapStateToProps = (state) => ({
  quizzes: state.quizzes.quizzes,
  loading: state.quizzes.loading,
  error: state.quizzes.error,
  errorDetail: state.quizzes.errorDetail,
});

/**
 * Redux action creators to be bound to component props
 * @type {Object.<string, Function>}
 */
const mapDispatchToProps = {
  updateQuizStatus,
  deleteQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizList);
