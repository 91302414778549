/**
 * @fileoverview Crossword list component for displaying all crosswords
 * Provides list view with status management and deletion capabilities
 */

import React from 'react';
import { connect } from 'react-redux';

import Loading from '../UI/Loading';
import CrosswordListItem from './CrosswordListItem';

import {
  updateCrosswordStatus,
  deleteCrossword,
} from '../../redux/actions/crosswords';

const CrosswordList = ({
  loading,
  crosswords,
  deleteCrossword,
  updateCrosswordStatus,
  error,
  errorDetail,
}) => {
  /**
   * Handles crossword deletion
   * @param {string} crosswordId - ID of crossword to delete
   */
  const handleDeleteCrossword = (crosswordId) => {
    deleteCrossword(crosswordId);
  };

  /**
   * Handles crossword status updates
   * @param {string} crosswordId - ID of crossword to update
   * @param {string} status - New status to set
   */
  const handleStatusUpdate = (crosswordId, status) => {
    updateCrosswordStatus({ crosswordId, status });
  };

  return error ? (
    <div>{errorDetail}</div>
  ) : loading ? (
    <Loading />
  ) : crosswords.length < 1 ? (
    <div
      style={{
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <p style={{ textAlign: 'center', color: 'grey' }}>
        Wow, such emptiness. Why don&apos;t you create a new crossword. :)
      </p>
    </div>
  ) : (
    <div className="activity-list">
      {crosswords.map((crossword) => (
        <CrosswordListItem
          key={crossword.crosswordId}
          crossword={crossword}
          onDelete={handleDeleteCrossword}
          onStatusUpdate={handleStatusUpdate}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  crosswords: state.crosswords.crosswords,
  loading: state.crosswords.loading,
  error: state.crosswords.error,
  errorDetail: state.crosswords.errorDetail,
});

const mapDispatchToProps = {
  updateCrosswordStatus,
  deleteCrossword,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrosswordList);
