/**
 * @fileoverview Page not found component
 * Error page displayed when navigating to non-existent routes
 */

import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Page not found component
 * Displays a friendly error message with a link back to the home page
 * when users navigate to routes that don't exist
 *
 * @returns {JSX.Element} Rendered 404 page
 */
const PageNotFound = () => {
  return (
    <div style={{ color: 'grey' }}>
      <h1>OOPS! How did you land here?</h1>
      <p>
        Let&apos;s get you back. Click{' '}
        <Link style={{ textDecoration: 'none' }} to="/">
          HERE
        </Link>{' '}
        to go to Home Page
      </p>
    </div>
  );
};

export default PageNotFound;
