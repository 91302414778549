/**
 * @fileoverview A wizard component for creating new Kreeda (game/quiz) instances
 * Handles the step-by-step creation process including type selection and detail entry
 */

import React, { useEffect, useState } from 'react';

import SelectType from './SelectType';
import AddDetails from './AddDetails';

function CreateKreeda({ closing, setClosing, initialType, skipTypeSelection }) {
  /**
   * @type {[number, Function]} Current step in the wizard
   */
  const [currentStep, setCurrentStep] = useState(skipTypeSelection ? 1 : 0);

  /**
   * @type {[Object, Function]} Kreeda state containing type, details, and settings
   */
  const [kreeda, setKreeda] = useState({
    type: initialType || '',
    details: {
      name: '',
      duration: '',
    },
    settings: {},
  });

  const steps = [
    <SelectType
      currentStep={currentStep}
      setKreeda={setKreeda}
      setCurrentStep={setCurrentStep}
    />,
    <AddDetails
      type={kreeda.type}
      setKreeda={setKreeda}
      setCurrentStep={setCurrentStep}
    />,
    // 'Settings',
  ];

  /**
   * Advances to the next step in the wizard if available
   */
  // const handleNext = () => {
  //   if (currentStep < steps.length - 1) {
  //     setCurrentStep(currentStep + 1);
  //   }
  // };

  /**
   * Effect to reset the wizard state when closing
   */
  useEffect(() => {
    if (closing === true) {
      setCurrentStep(skipTypeSelection ? 1 : 0);
      setKreeda({
        type: initialType || '',
        details: {
          name: '',
          duration: '',
        },
        settings: {},
      });
      setClosing(false);
    }
  }, [closing]);

  return (
    <>
      <div className="create-container">
        <div className="create-content">
          {steps.map((step, index) => (
            <div
              key={index}
              className="create-step"
              style={{ transform: `translateX(-${currentStep * 100}%)` }}
            >
              {step}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CreateKreeda;
