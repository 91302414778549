/**
 * @fileoverview Quiz submissions listing page
 * Displays all submissions for a specific quiz
 */

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Loading from '../../components/UI/Loading';

import { getQuiz, getSubmissions } from '../../redux/actions/quizzes';

/**
 * Quiz submissions listing component
 * Features:
 * - Automatic data fetching on mount
 * - Tabular display of submissions
 * - Loading state handling
 * - Empty state messaging
 *
 * @param {Object} props - Component props
 * @param {Array<Object>} props.quizzes - Array of quiz objects
 * @param {Function} props.getQuiz - Action to fetch quiz details
 * @param {Function} props.getSubmissions - Action to fetch quiz submissions
 * @param {boolean} props.loading - Loading state
 * @returns {JSX.Element} Rendered submissions list
 */
const ListSubmissions = ({ quizzes, getQuiz, getSubmissions, loading }) => {
  const params = useParams();
  const quizId = params.id;

  const quiz = quizzes.find((quiz) => quiz.quizId === quizId);

  /**
   * Fetches quiz and submissions data if not already loaded
   */
  useEffect(() => {
    if (!quiz) {
      getQuiz(quizId);
      getSubmissions(quizId);
    }
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="submissions-container">
      {quiz && quiz.submissions && quiz.submissions.length > 0 ? (
        <>
          <div className="submission-column--name">
            <div className="submission--header">
              <p>Name</p>
            </div>
            {quiz.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.name}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--email">
            <div className="submission--header">
              <p>Email</p>
            </div>
            {quiz.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.email}</p>
              </div>
            ))}
          </div>
          <div className="submission-column--score">
            <div className="submission--header">
              <p>Score</p>
            </div>
            {quiz.submissions.map((sub, i) => (
              <div
                key={i}
                className={`submission${i % 2 === 0 ? '--alt' : ''}`}
              >
                <p>{sub.score}</p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p>No Submissions so far...</p>
      )}
    </div>
  );
};

/**
 * Maps Redux state to component props
 * @param {Object} state - Redux state
 * @returns {Object} Props for component
 */
const mapStateToProps = (state) => {
  return {
    quizzes: state.quizzes.quizzes,
    loading: state.quizzes.loading,
    error: state.quizzes.error,
  };
};

/**
 * Redux action creators to be bound to component props
 * @type {Object.<string, Function>}
 */
const mapDispatchToProps = {
  getQuiz,
  getSubmissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListSubmissions);
