/**
 * @fileoverview Navigation items component for main application navigation
 * Provides consistent navigation links with active state handling
 */

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faComments,
  faHashtag,
  faHome,
  faQuestion,
  faSitemap,
  faSquarePollVertical,
} from '@fortawesome/free-solid-svg-icons';

/**
 * Navigation items component that renders the main navigation links
 * Features:
 * - Home link
 * - Activity type links (Quizzes, Crosswords, etc.)
 * - Organization and library access
 * - Active state highlighting based on current route
 * - Icon indicators for each section
 *
 * @returns {JSX.Element} Collection of navigation links
 */
const NavItems = () => {
  const location = useLocation();
  return (
    <>
      <Link
        className={
          location.pathname === '/'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/"
      >
        <FontAwesomeIcon icon={faHome} />
        Home
      </Link>
      <Link
        className={
          location.pathname.includes('/quizzes')
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/quizzes"
      >
        <FontAwesomeIcon icon={faQuestion} />
        Quizzes
      </Link>
      <Link
        className={
          location.pathname === '/crosswords'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/crosswords"
      >
        <FontAwesomeIcon icon={faHashtag} />
        Crosswords
      </Link>
      <Link
        className={
          location.pathname === '/surveys'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/surveys"
      >
        <FontAwesomeIcon icon={faSquarePollVertical} />
        Surveys
      </Link>
      <Link
        className={
          location.pathname === '/interactive-quizzes'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/interactive-quizzes"
      >
        <FontAwesomeIcon icon={faComments} />
        Interactive Quizzes
      </Link>
      <Link
        className={
          location.pathname === '/library'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/library"
      >
        <FontAwesomeIcon icon={faBook} />
        Library
      </Link>
      <Link
        className={
          location.pathname === '/organisations'
            ? 'nav-pane__link nav-pane__link-active'
            : 'nav-pane__link'
        }
        to="/organisations"
      >
        <FontAwesomeIcon icon={faSitemap} />
        Organisations
      </Link>
    </>
  );
};

export default NavItems;
