/**
 * @fileoverview Redux store initialization and configuration
 * Sets up the Redux store with combined reducers for quizzes and crosswords
 */

import { configureStore } from '@reduxjs/toolkit';

import quizzesSlice from '../reducers/quizzes.js';
import crosswordsSlice from '../reducers/crosswords.js';

/**
 * Creates and configures the Redux store
 * Combines reducers for different features into a single store
 * @returns {import('@reduxjs/toolkit').EnhancedStore} Configured Redux store
 */
export default () => {
  const store = configureStore({
    reducer: {
      // Combined reducers configuration
      quizzes: quizzesSlice.reducer,
      crosswords: crosswordsSlice.reducer,
    },
  });
  return store;
};
