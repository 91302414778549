/**
 * @fileoverview Application header component with navigation, search, and user controls
 * Provides global navigation, activity search, and user account management
 */

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIdBadge,
  faMagnifyingGlass,
  faRightFromBracket,
  faSliders,
  faPlus,
  faBars,
  faList,
  faTable,
} from '@fortawesome/free-solid-svg-icons';

import NavItems from './NavItems';
import Modal from './Modal';
import CreateKreeda from '../CreateWizard/CreateKreeda';

/**
 * Header component that provides top-level navigation and controls
 * Features include:
 * - Mobile-responsive navigation
 * - Activity search with dropdown results
 * - Create activity modal
 * - User account menu
 *
 * @param {Object} props - Component props
 * @param {Object} props.auth - Authentication object
 * @param {string} props.auth.name - User's display name
 * @param {Function} props.auth.logout - Logout function
 * @returns {JSX.Element} Rendered header component
 */
const Header = ({ auth }) => {
  const quizzes = useSelector((state) => state.quizzes.quizzes);
  const crosswords = useSelector((state) => state.crosswords.crosswords);
  const location = useLocation();
  const refAccount = useRef();
  const refNav = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  /**
   * Opens the create activity modal
   * @param {React.MouseEvent} event - Click event object
   */
  const handleModalOpen = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  /**
   * Closes any open menus when clicking outside
   * Handles both account dropdown and mobile navigation
   * @param {MouseEvent} [e] - Click event object
   */
  const closeOpenMenus = (e) => {
    if (e) {
      if (isOpen && !refAccount.current?.contains(e.target)) {
        setIsOpen(false);
      }
      const menuToggle = document.getElementById('menu');
      if (menuToggle.checked && !refNav.current?.contains(e.target)) {
        menuToggle.checked = false;
      } else if (
        refNav.current?.contains(e.target) &&
        e.target.className.includes('nav-pane__link')
      ) {
        menuToggle.checked = false;
      }
    } else {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  };

  /**
   * Handles search input changes
   * Filters quizzes and crosswords based on search term
   * @param {React.ChangeEvent<HTMLInputElement>} e - Change event object
   */
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.trim() === '') {
      setSearchResults([]);
      setShowResults(false);
      return;
    }

    const matchingQuizzes = quizzes
      .filter((quiz) => quiz.name.toLowerCase().includes(term))
      .map((quiz) => ({
        id: quiz.quizId,
        name: quiz.name,
        type: 'quiz',
        url: `/quizzes/${quiz.quizId}`,
      }));

    const matchingCrosswords = crosswords
      .filter((crossword) => crossword.name.toLowerCase().includes(term))
      .map((crossword) => ({
        id: crossword.crosswordId,
        name: crossword.name,
        type: 'crossword',
        url: `/crosswords/${crossword.crosswordId}`,
      }));

    setSearchResults([...matchingQuizzes, ...matchingCrosswords]);
    setShowResults(true);
  };

  /**
   * Handles search input blur
   * Hides search results with a small delay to allow result clicks
   */
  const handleBlur = () => {
    // Small delay to allow click on search result
    setTimeout(() => {
      setShowResults(false);
    }, 200);
  };

  useEffect(() => {
    closeOpenMenus();
  }, [location]);

  document.addEventListener('mousedown', closeOpenMenus);

  return (
    <div className="header">
      <div className="mobile-nav">
        <input
          className="menu-toggle"
          id="menu"
          type="checkbox"
          defaultChecked={false}
        ></input>
        <label htmlFor="menu" className="menu-container">
          <FontAwesomeIcon icon={faBars} className="menu-button" />
        </label>
        <div className="mobile-nav-pane" ref={refNav}>
          <button
            onClick={() => {
              document.getElementById('menu').checked = false;
            }}
          >
            X
          </button>
          <NavItems />
        </div>
        <Link className="root-link" to="/">
          <div className="logo-g">G</div>&nbsp;
          <div className="logo-k">K</div>
          <div className="logo-gyaan">GYAAN</div>&nbsp;
          <div className="logo-kreeda">KREEDA</div>
        </Link>
      </div>
      <div className="header-rest">
        <div className="search-container">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <input
            type="text"
            className="search"
            placeholder="Search activities"
            value={searchTerm}
            onChange={handleSearch}
            onFocus={() => setShowResults(true)}
            onBlur={handleBlur}
          />
          {showResults && searchResults.length > 0 && (
            <div className="search-results">
              {searchResults.map((result) => (
                <Link
                  key={result.id}
                  to={result.url}
                  className="search-result-item"
                >
                  <FontAwesomeIcon
                    icon={result.type === 'quiz' ? faList : faTable}
                    className="search-result-icon"
                  />
                  <span>{result.name}</span>
                  <span className="search-result-type">{result.type}</span>
                </Link>
              ))}
            </div>
          )}
        </div>
        <button
          className="btn--primary web"
          onClick={handleModalOpen}
          title="Create a new quiz, crossword, or survey"
        >
          <FontAwesomeIcon icon={faPlus} />
          <span>Create</span>
        </button>
        <button
          className="btn--primary mobile"
          onClick={handleModalOpen}
          title="Create new"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <Modal
          id="create-kreeda"
          component={CreateKreeda}
          isOpen={isModalOpen}
          onClose={handleModalClose}
        />
        <div className="header__account-section" ref={refAccount}>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="btn--secondary mobile"
          >
            {auth.name
              .split(' ')
              .map((n) => n[0])
              .join('')}
          </button>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="btn--secondary web"
          >
            {auth.name}
          </button>

          <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
            <Link to="/profile">
              <FontAwesomeIcon icon={faIdBadge} />
              Profile
            </Link>
            <Link to="/settings">
              <FontAwesomeIcon icon={faSliders} />
              Settings
            </Link>
            <button onClick={() => auth.logout()}>
              <FontAwesomeIcon icon={faRightFromBracket} />
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
