/**
 * @fileoverview Second step of activity creation wizard
 * Handles details entry for different activity types (quiz, crossword)
 */

import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import QuizForm from '../Quiz/QuizForm';
import CrosswordForm from '../Crossword/CrosswordForm';
import Loading from '../UI/Loading.jsx';

import { setlastCreatedQuizId } from '../../redux/reducers/quizzes.js';
import { setlastCreatedCrosswordId } from '../../redux/reducers/crosswords.js';
import { createQuiz } from '../../redux/actions/quizzes.js';
import { createCrossword } from '../../redux/actions/crosswords.js';
import loading from '../../redux/selectors/loading.js';

const AddDetails = ({
  loading,
  type,
  lastCreatedQuizId,
  setlastCreatedQuizId,
  createQuiz,
  lastCreatedCrosswordId,
  setlastCreatedCrosswordId,
  createCrossword,
  setKreeda,
  setCurrentStep,
}) => {
  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  }
  switch (type) {
    case 'quiz':
      if (lastCreatedQuizId) {
        return (
          <div className="centered">
            <button
              className="btn--primary"
              onClick={() => {
                const modal = document.getElementById('create-kreeda');
                modal.style.display = 'none';
                setKreeda({
                  type: '',
                  details: {
                    name: '',
                    duration: '',
                  },
                  settings: {},
                });
                setCurrentStep(0);
                const quiId = lastCreatedQuizId;
                setlastCreatedQuizId({ quizId: null });
                navigate(`/quizzes/${quiId}`);
              }}
            >
              Finish
            </button>
          </div>
        );
      }

      return (
        <QuizForm
          onSubmit={(quiz) => {
            createQuiz(quiz);
          }}
          onCancel={() => {
            setKreeda({
              type: '',
              details: {
                name: '',
                duration: '',
              },
              settings: {},
            });
            setCurrentStep(0);
          }}
        />
      );

    case 'crossword':
      if (lastCreatedCrosswordId) {
        return (
          <div className="centered">
            <button
              className="btn--primary"
              onClick={() => {
                const modal = document.getElementById('create-kreeda');
                modal.style.display = 'none';
                setKreeda({
                  type: '',
                  details: {
                    name: '',
                    duration: '',
                  },
                  settings: {},
                });
                setCurrentStep(0);
                const crosswordId = lastCreatedCrosswordId;
                setlastCreatedCrosswordId({ crosswordId: null });
                navigate(`/crosswords/${crosswordId}`);
              }}
            >
              Finish
            </button>
          </div>
        );
      }

      return (
        <CrosswordForm
          onSubmit={(crossword) => {
            createCrossword(crossword);
          }}
          onCancel={() => {
            setKreeda({
              type: '',
              details: {
                name: '',
                duration: '',
              },
              settings: {},
            });
            setCurrentStep(0);
          }}
        />
      );
  }
  return <div>AddDetails</div>;
};

const mapStateToProps = (state) => {
  return {
    loading: loading(state.quizzes.loading, state.crosswords.loading),
    error: state.quizzes.error,
    errorDetail: state.quizzes.errorDetail,
    lastCreatedQuizId: state.quizzes.lastCreatedQuizId,
    lastCreatedCrosswordId: state.crosswords.lastCreatedCrosswordId,
  };
};

const mapDispatchToProps = {
  createQuiz,
  setlastCreatedQuizId,
  createCrossword,
  setlastCreatedCrosswordId,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDetails);
