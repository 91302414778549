/**
 * @fileoverview Code/link sharing component with copy functionality
 * Provides a read-only input field with one-click copy capability
 */

import React from 'react';

/**
 * Code component for displaying and copying sharable links/codes
 * Features:
 * - Read-only input field that auto-selects on focus
 * - Copy to clipboard button with success state
 * - Unique ID for multiple instances
 *
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the input field
 * @param {string} props.link - The link or code to be displayed and copied
 * @param {boolean} props.copied - Whether the content has been copied
 * @param {Function} props.onCopy - Callback function when content is copied
 * @returns {JSX.Element} Rendered code sharing component
 */
const Code = ({ id, link, copied, onCopy }) => {
  return (
    <div className="code-container">
      <input
        id={`link-${id}`}
        type="text"
        /**
         * Auto-selects the input content on focus
         * @param {React.FocusEvent<HTMLInputElement>} e - Focus event
         */
        onFocus={(e) => {
          e.target.select();
        }}
        onChange={() => {}}
        value={link}
      />
      <button
        className="btn--primary"
        /**
         * Copies the input content to clipboard and triggers callback
         * @param {React.MouseEvent<HTMLButtonElement>} e - Click event
         */
        onClick={(_e) => {
          const input = document.getElementById(`link-${id}`);
          navigator.clipboard.writeText(input.value);
          onCopy();
        }}
      >
        {copied ? 'COPIED' : 'COPY'}
      </button>
    </div>
  );
};

export default Code;
