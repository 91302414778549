/**
 * @fileoverview Page component for editing quiz details and managing questions
 * Provides interface for updating quiz metadata and CRUD operations on questions
 */

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Loading from '../../components/UI/Loading';
import QuizForm from '../../components/Quiz/QuizForm';
import QuestionItem from '../../components/Question/QuestionItem';
import QuestionForm from '../../components/Question/QuestionForm';
import QuestionItemSkeleton from '../../components/UI/QuestionItemSkeleton';

import {
  getQuizzes,
  getQuiz,
  updateQuiz,
  updateQuestion,
  addQuestion,
  removeQuestion,
  deleteQuiz,
} from '../../redux/actions/quizzes.js';
import { setEditingQuestionId } from '../../redux/reducers/quizzes.js';

/**
 * EditQuiz page component
 * @param {Object} props - Component props
 * @param {Array<Quiz>} props.quizzes - List of quizzes from Redux store
 * @param {Function} props.getQuiz - Action to fetch quiz details
 * @param {Function} props.setEditingQuestionId - Action to set currently editing question
 * @param {Function} props.updateQuiz - Action to update quiz details
 * @param {Function} props.deleteQuiz - Action to delete a quiz
 * @param {Function} props.addQuestion - Action to add a new question
 * @param {Function} props.updateQuestion - Action to update existing question
 * @param {Function} props.removeQuestion - Action to remove a question
 * @param {boolean} props.loading - Loading state from Redux
 * @param {string|null} props.editingQuestionId - ID of question being edited
 * @returns {JSX.Element} Rendered page component
 */
const EditQuiz = ({
  quizzes,
  getQuiz,
  setEditingQuestionId,
  updateQuiz,
  deleteQuiz,
  addQuestion,
  updateQuestion,
  removeQuestion,
  loading,
  editingQuestionId,
}) => {
  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const quizId = params.id;
  let quiz = quizzes.find((quiz) => quiz && quiz.quizId === quizId);

  /**
   * Effect to fetch quiz details if not already loaded
   * Cleans up editing state on unmount
   */
  useEffect(() => {
    if (!quiz || !quiz.questions) getQuiz(quizId);

    return () => {
      setEditingQuestionId({ questionId: '' });
    };
  }, []);

  const emptyQuestion = {
    text: '',
    optionA: '',
    optionB: '',
    optionC: '',
    optionD: '',
    answer: 'A',
  };

  /**
   * Handles quiz metadata updates
   * @param {Object} quiz - Updated quiz data
   */
  const handleQuizUpdate = (quiz) => {
    updateQuiz({
      quizId: quiz.quizId,
      data: { name: quiz.name, duration: quiz.duration },
    });
    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  /**
   * Handles quiz deletion and navigates back to quiz list
   */
  const handleQuizDelete = () => {
    deleteQuiz(quiz.quizId);
    navigate('/quizzes');
  };

  /**
   * Sets a question into edit mode
   * @param {Object} question - Question to edit
   */
  const handleEditingQuestionItem = (question) => {
    setEditingQuestionId({ questionId: question.questionId });
  };

  const handleCancelEditingQuestionItem = () => {
    setEditingQuestionId({ questionId: null });
  };

  /**
   * Handles updates to existing questions
   * @param {Object} question - Updated question data
   */
  const handleQuestionItemUpdate = (question) => {
    updateQuestion({ quizId: quiz.quizId, question });
  };

  /**
   * Handles adding new questions to the quiz
   * @param {Object} question - New question data
   */
  const handleAddQuestionItem = (question) => {
    addQuestion({ quizId: quiz.quizId, question });
  };

  /**
   * Handles question deletion
   * @param {Object} question - Question to remove
   */
  const handleRemoveWordItem = (question) => {
    removeQuestion({
      quizId: quiz.quizId,
      questionId: question.questionId,
    });
  };

  if (loading) return <Loading />;

  return (
    quiz && (
      <div className="activity-container">
        {isEditing ? (
          <QuizForm
            quiz={quiz}
            onSubmit={handleQuizUpdate}
            onCancel={handleEditCancel}
          />
        ) : (
          <div className="activity-item-no-hover">
            <div className="activity-header">
              <h3>{quiz.name}</h3>
            </div>
            <div className="activity-meta">
              <span>{quiz.duration / 60} min </span>
            </div>
            <div className="activity-actions">
              <button
                className="btn--primary"
                onClick={() => {
                  setIsEditing(true);
                }}
                disabled={quiz.status === 'published'}
              >
                Edit
              </button>
              <button
                className="btn--delete"
                onClick={handleQuizDelete}
                disabled={quiz.status === 'published'}
              >
                Delete
              </button>
            </div>
          </div>
        )}
        {quiz.status === 'draft' && (
          <button
            className={isAddQuestionOpen ? 'btn--secondary' : 'btn--primary'}
            onClick={(_e) => {
              const form = document.getElementById('create');
              if (isAddQuestionOpen) {
                form.style.display = 'none';
              } else {
                form.style.display = 'flex';
              }
              setIsAddQuestionOpen(!isAddQuestionOpen);
            }}
          >
            {isAddQuestionOpen ? 'Cancel' : 'Add Question'}
          </button>
        )}
        <QuestionForm
          action="create"
          question={emptyQuestion}
          onAdd={handleAddQuestionItem}
        />
        {quiz.questions && quiz.questions.length > 0 && (
          <div className="activity-content">
            {quiz.questions.map((question) =>
              editingQuestionId === question.questionId ? (
                question.loading ? (
                  <QuestionItemSkeleton key={question.questionId} />
                ) : (
                  <QuestionForm
                    action="update"
                    key={question.questionId}
                    question={question}
                    onAdd={handleQuestionItemUpdate}
                    onCancel={handleCancelEditingQuestionItem}
                  />
                )
              ) : (
                <QuestionItem
                  key={question.questionId}
                  question={question}
                  quizStatus={quiz.status}
                  onEdit={handleEditingQuestionItem}
                  onDelete={handleRemoveWordItem}
                />
              )
            )}
          </div>
        )}
      </div>
    )
  );
};

/**
 * Maps Redux state to component props
 * @param {Object} state - Redux state
 * @returns {Object} Props for component
 */
const mapStateToProps = (state) => {
  return {
    quizzes: state.quizzes.quizzes,
    loading: state.quizzes.loading,
    error: state.quizzes.error,
    errorDetail: state.quizzes.errorDetail,
    editingQuestionId: state.quizzes.editingQuestionId,
  };
};

/**
 * Redux action creators to be bound to component props
 * @type {Object.<string, Function>}
 */
const mapDispatchToProps = {
  getQuizzes,
  getQuiz,
  updateQuiz,
  addQuestion,
  updateQuestion,
  removeQuestion,
  setEditingQuestionId,
  deleteQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditQuiz);
