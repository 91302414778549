/**
 * @fileoverview Service for managing quiz-related operations including CRUD operations
 * and submission handling. Communicates with the backend API to manage quiz data.
 */

import { RESOURCENOTFOUND, FIELDMISSING } from '../utils/errors.js';
import { configService } from './config.service.js';

const { prefixUrl } = await configService.appConfig;

/**
 * Fetches all quizzes for the authenticated user
 * @returns {Promise<Array<Quiz>>} Array of quiz objects
 * @throws {Error} If fetching fails or session is invalid
 */
async function getQuizzes() {
  const res = await fetch(`${prefixUrl}/quizzes`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Unable to fetch quizzes. Please try after some time.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }
  const responseBody = await res.json();
  return responseBody.Data.Quizzes;
}

/**
 * Creates a new quiz
 * @param {Quiz} quiz - Quiz data to create
 * @returns {Promise<Quiz>} Created quiz object
 * @throws {Error} If creation fails or required fields are missing
 */
async function createQuiz(quiz) {
  const res = await fetch(`${prefixUrl}/quizzes`, {
    method: 'POST',
    body: JSON.stringify({ Data: { ...quiz } }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === FIELDMISSING) {
          throw new Error(
            'A required field is missing. Please contact administrator.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return responseBody.Data.Quiz;
}

/**
 * Retrieves detailed information for a specific quiz
 * @param {string} quizId - ID of the quiz to fetch
 * @returns {Promise<Quiz>} Quiz details
 * @throws {Error} If quiz doesn't exist or access is denied
 */
async function getQuizDetails(quizId) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error('The quiz you requested does not exist.');
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return responseBody.Data.Quiz;
}

/**
 * Updates an existing quiz
 * @param {string} quizId - ID of the quiz to update
 * @param {Quiz} data - Updated quiz data
 * @returns {Promise<Object>} Updated quiz object
 * @throws {Error} If quiz doesn't exist or update fails
 */
async function updateQuiz(quizId, data) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}`, {
    method: 'PATCH',
    body: JSON.stringify({ Data: { ...data } }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to update quiz. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return { quiz: { ...responseBody.Data.Quiz } };
}

/**
 * Deletes a quiz
 * @param {string} quizId - ID of the quiz to delete
 * @returns {Promise<{quizId: string}>} Deleted quiz ID
 * @throws {Error} If quiz doesn't exist or deletion fails
 */
async function deleteQuiz(quizId) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to delete quiz. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  return { quizId };
}

/**
 * Updates the status of a quiz (e.g., publish/unpublish)
 * @param {string} quizId - ID of the quiz
 * @param {string} status - New status to set
 * @returns {Promise<{quiz: Quiz}>} Updated quiz object
 * @throws {Error} If status update fails
 */
async function updateQuizStatus(quizId, status) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}/status`, {
    method: 'PUT',
    body: JSON.stringify({ Data: { status } }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to publish quiz. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return { quiz: { ...responseBody.Data.Quiz, quizId } };
}

/**
 * Adds a new question to a quiz
 * @param {string} quizId - ID of the quiz
 * @param {Question} question - Question data to add
 * @returns {Promise<{quizId: String; question: Question}>} Added question object
 * @throws {Error} If question addition fails
 */
async function addQuestion(quizId, question) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}/questions`, {
    method: 'POST',
    body: JSON.stringify({ Data: { ...question } }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to add question. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return { quizId, question: { ...responseBody.Data.Question } };
}

/**
 * Updates an existing question in a quiz
 * @param {string} quizId - ID of the quiz
 * @param {Question} question - Updated question data
 * @returns {Promise<{quizId: String; question: Question}>} Updated question object
 * @throws {Error} If question update fails
 */
async function updateQuestion(quizId, question) {
  const res = await fetch(
    `${prefixUrl}/quizzes/${quizId}/questions/${question.questionId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({ Data: { ...question } }),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  );

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to update question. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return { quizId, question: { ...responseBody.Data.Question } };
}

/**
 * Removes a question from a quiz
 * @param {string} quizId - ID of the quiz
 * @param {string} questionId - ID of the question to remove
 * @returns {Promise<{quizId: string; questionId: string}>} Removed question ID
 * @throws {Error} If question removal fails
 */
async function removeQuestion(quizId, questionId) {
  const res = await fetch(
    `${prefixUrl}/quizzes/${quizId}/questions/${questionId}`,
    {
      method: 'DELETE',
      credentials: 'include',
    }
  );

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to remove question. The quiz you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  return { quizId, questionId };
}

/**
 * Fetches all submissions for a specific quiz
 * @param {string} quizId - ID of the quiz
 * @returns {Promise<Array<Submission>>} Array of submission objects
 * @throws {Error} If fetching submissions fails
 */
async function getSubmissions(quizId) {
  const res = await fetch(`${prefixUrl}/quizzes/${quizId}/submissions`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error('The quiz you requested does not exist.');
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return responseBody.Data.Submissions;
}

const quizService = {
  getQuizzes,
  createQuiz,
  getQuizDetails,
  updateQuiz,
  deleteQuiz,
  updateQuizStatus,
  addQuestion,
  updateQuestion,
  removeQuestion,
  getSubmissions,
};

export default quizService;
