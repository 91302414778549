/**
 * @fileoverview Crossword editing page
 * Provides interface for editing crossword details and managing words
 * @module pages/Crossword/EditCrossword
 */

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Loading from '../../components/UI/Loading';
import CrosswordForm from '../../components/Crossword/CrosswordForm';
import WordItem from '../../components/Word/WordItem';
import WordBox from '../../components/Word/WordBox';
import {
  getCrossword,
  updateCrossword,
  updateWords,
  deleteCrossword,
} from '../../redux/actions/crosswords';

/**
 * Crossword editing page component
 * Features:
 * - Crossword details editing
 * - Word management (add, edit, delete)
 * - Status-based action disabling
 * - Navigation integration
 *
 * @param {Object} props - Component props
 * @param {Array<Crossword>} props.crosswords - Array of crossword objects
 * @param {Function} props.getCrossword - Action to fetch crossword details
 * @param {Function} props.updateCrossword - Action to update crossword details
 * @param {Function} props.updateWords - Action to update crossword words
 * @param {Function} props.deleteCrossword - Action to delete crossword
 * @param {boolean} props.loading - Loading state
 * @returns {JSX.Element} Rendered edit page
 */
const EditCrossword = ({
  crosswords,
  getCrossword,
  updateCrossword,
  updateWords,
  deleteCrossword,
  loading,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdatingWords, setIsUpdatingWords] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const crosswordId = params.id;
  let crossword = crosswords.find(
    (crossword) => crossword && crossword.crosswordId === crosswordId
  );

  /**
   * Fetches crossword data if not already loaded
   */
  useEffect(() => {
    if (!crossword || !crossword.words) getCrossword(crosswordId);
  }, []);

  /**
   * Handles crossword details update
   * @param {Crossword} crossword - Updated crossword data
   */
  const handleCrosswordUpdate = (crossword) => {
    updateCrossword({
      crosswordId: crossword.crosswordId,
      data: { name: crossword.name, duration: crossword.duration },
    });
    setIsEditing(false);
  };

  /**
   * Handles edit cancellation
   * Exits edit mode without saving changes
   */
  const handleEditCancel = () => {
    setIsEditing(false);
  };

  /**
   * Handles crossword deletion
   * Deletes the crossword and navigates back to list
   */
  const handleCrosswordDelete = () => {
    deleteCrossword(crossword.crosswordId);
    navigate('/crosswords');
  };

  /**
   * Handles words update
   * @param {Array<Word>} words - Updated words array
   */
  const handleUpdateWords = (words) => {
    updateWords({ crosswordId: crossword.crosswordId, words });
    setIsUpdatingWords(false);
  };

  /**
   * Handles word edit cancellation
   * Exits word edit mode without saving changes
   */
  const handleEditWordsCancel = () => {
    setIsUpdatingWords(false);
  };

  if (loading) return <Loading />;

  return (
    crossword && (
      <div className="activity-container">
        {isEditing ? (
          <CrosswordForm
            crossword={crossword}
            onSubmit={handleCrosswordUpdate}
            onCancel={handleEditCancel}
          />
        ) : (
          <div className="activity-item-no-hover">
            <div className="activity-header">
              <h3>{crossword.name}</h3>
            </div>
            <div className="activity-meta">
              <span>{crossword.duration / 60} min </span>
            </div>
            <div className="activity-actions">
              <button
                className="btn--primary"
                onClick={() => {
                  setIsEditing(true);
                }}
                disabled={crossword.status === 'published'}
              >
                Edit
              </button>
              <button
                className="btn--delete"
                onClick={handleCrosswordDelete}
                disabled={crossword.status === 'published'}
              >
                Delete
              </button>
            </div>
          </div>
        )}

        {crossword.words && crossword.words.length > 0 ? (
          isUpdatingWords ? (
            <WordBox
              words={crossword.words}
              onUpdate={handleUpdateWords}
              onCancel={handleEditWordsCancel}
            />
          ) : (
            <div className="word-item__container">
              <div className="word-item__content">
                <div className="word-item__answer--heading">
                  <h4>ANSWER</h4>
                </div>
                <div className="word-item__clue--heading">
                  <h4>CLUE</h4>
                </div>
              </div>
              {crossword.words.map((word, index) => (
                <WordItem key={index} word={word} />
              ))}
              {crossword.status === 'draft' && (
                <div className="word-item__controls">
                  <button
                    className="btn--primary"
                    onClick={() => setIsUpdatingWords(true)}
                  >
                    Edit Words
                  </button>
                </div>
              )}
            </div>
          )
        ) : isUpdatingWords ? (
          <WordBox
            words={crossword.words}
            onUpdate={handleUpdateWords}
            onCancel={handleEditWordsCancel}
          />
        ) : (
          <button
            className="btn--primary"
            onClick={() => setIsUpdatingWords(true)}
          >
            Add Words
          </button>
        )}
      </div>
    )
  );
};

/**
 * Maps Redux state to component props
 * @param {Object} state - Redux state
 * @param {import('../../redux/reducers/crosswords').CrosswordsState} state.crosswords - Crosswords state slice
 * @returns {Object} Props for component
 */
const mapStateToProps = (state) => {
  return {
    crosswords: state.crosswords.crosswords,
    loading: state.crosswords.loading,
    error: state.crosswords.error,
    errorDetail: state.crosswords.errorDetail,
    editingWordId: state.crosswords.editingWordId,
  };
};

/**
 * Redux action creators to be bound to component props
 * @type {Object.<string, Function>}
 */
const mapDispatchToProps = {
  getCrossword,
  updateCrossword,
  updateWords,
  deleteCrossword,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCrossword);
