/**
 * @fileoverview A loading spinner component for indicating loading states
 */

import React from 'react';

/**
 * Loading component that displays an animated spinner with text
 * Renders a structured loading indicator with:
 * - A container with a spinning animation
 * - A placeholder for loading text
 * @returns {JSX.Element} Rendered loading spinner component
 */
const Loading = () => {
  return (
    <div className="loader-container">
      <ul>
        <li>
          <div className="loader">
            <div className="child"></div>
          </div>
        </li>
        <li>
          <div className="text"></div>
        </li>
      </ul>
    </div>
  );
};

export default Loading;
