/**
 * @fileoverview Reusable modal component with click outside and escape key handling
 * Provides a flexible container for rendering modal content with consistent behavior
 */

import React, { useRef, useState, useEffect } from 'react';

import { useClickOutside } from '../../hooks/ui';

/**
 * Modal component that renders content in an overlay
 * Handles closing via click outside, escape key, and provides closing animation state
 *
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the modal
 * @param {React.ComponentType} props.component - Component to render inside the modal
 * @param {Object} props.componentProps - Props to pass to the rendered component
 * @param {boolean} props.isOpen - Controls modal visibility
 * @param {Function} props.onClose - Callback function when modal should close
 * @returns {JSX.Element|null} Rendered modal or null if closed
 */
const Modal = ({
  id,
  component: Component,
  componentProps,
  isOpen,
  onClose,
}) => {
  const ref = useRef();
  const [closing, setClosing] = useState(false);

  // Handle clicks outside the modal content
  useClickOutside(ref, () => {
    if (isOpen) {
      setClosing(true);
      onClose();
    }
  });

  /**
   * Effect to handle escape key press
   * Adds and removes event listener for keydown events
   */
  useEffect(() => {
    /**
     * Handler for keydown events, specifically for escape key
     * @param {KeyboardEvent} event - Keyboard event object
     */
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && isOpen) {
        setClosing(true);
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div id={id} className="modal-container" style={{ display: 'flex' }}>
      <div ref={ref} className="modal-content">
        <Component
          {...componentProps}
          closing={closing}
          setClosing={setClosing}
        />
      </div>
    </div>
  );
};

export default Modal;
