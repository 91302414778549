/**
 * @fileoverview Utility functions for crossword puzzle validation and analysis
 * Provides algorithms to determine if a set of words can form a valid crossword
 */

/**
 * Determines if a valid crossword puzzle can be built from a set of words
 * Uses a graph-based approach to check if all words can be connected through shared letters
 * @param {string[]} words - Array of words to check
 * @returns {boolean} True if a valid crossword can be built, false otherwise
 */
export function canBuildCrossword(words) {
  if (words.length < 2) return false;

  /**
   * Checks if two words share any common characters
   * @param {string} word1 - First word to check
   * @param {string} word2 - Second word to check
   * @returns {boolean} True if words share at least one character
   */
  function findOverlap(word1, word2) {
    for (let i = 0; i < word1.length; i++) {
      for (let j = 0; j < word2.length; j++) {
        if (word1[i] === word2[j]) {
          return true;
        }
      }
    }
    return false;
  }

  // Create an adjacency list representation of word connections
  // Each word is a vertex, and edges exist between words that share characters
  const graph = {};
  words.forEach((word) => {
    graph[word] = [];
  });

  // Build the graph
  for (let i = 0; i < words.length; i++) {
    for (let j = i + 1; j < words.length; j++) {
      if (findOverlap(words[i], words[j])) {
        graph[words[i]].push(words[j]);
        graph[words[j]].push(words[i]);
      }
    }
  }

  /**
   * Performs Depth-First Search to check if all words are connected
   * @param {string} word - Current word being visited
   * @param {Set<string>} visited - Set of already visited words
   */
  function dfs(word, visited) {
    visited.add(word);
    for (let neighbor of graph[word]) {
      if (!visited.has(neighbor)) {
        dfs(neighbor, visited);
      }
    }
  }

  const visited = new Set();
  dfs(words[0], visited);

  // If all words are visited, a crossword can be built
  return visited.size === words.length;
}
