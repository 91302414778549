/**
 * @fileoverview Navigation pane container component
 * Provides styling and structure for the main navigation items
 */

import React from 'react';

import NavItems from './NavItems';

/**
 * Navigation pane component that wraps the navigation items
 * Serves as a styled container for the main application navigation
 * Uses NavItems component to render the actual navigation links
 *
 * @returns {JSX.Element} Navigation pane container with navigation items
 */
const NavPane = () => {
  return (
    <div className="nav-pane">
      <NavItems />
    </div>
  );
};

export default NavPane;
