/**
 * @fileoverview Individual quiz item component for list display
 * Provides detailed view and actions for a single quiz
 */

import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxArchive,
  faCircleDown,
  faCircleUp,
  faClipboardCheck,
  faShareNodes,
  faTrash,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';

import Modal from '../UI/Modal';
import Code from '../UI/Code.jsx';
import CardSkeleton from '../UI/CardSkeleton';

import { configService } from '../../services/config.service.js';
import { useClickOutside } from '../../hooks/ui.js';

const { playServer } = await configService.appConfig;

/**
 * Quiz list item component with interactive features
 * Features:
 * - Metadata display (duration, question count, submissions)
 * - Status management (publish/unpublish)
 * - Share functionality with code copying
 * - Options menu (submissions, archive, delete)
 * - Loading state handling
 *
 * @param {Object} props - Component props
 * @param {Object} props.quiz - Quiz data
 * @param {string} props.quiz.quizId - Unique identifier
 * @param {string} props.quiz.name - Quiz name
 * @param {string} props.quiz.status - Current status
 * @param {string} props.quiz.code - Share code
 * @param {number} props.quiz.duration - Duration in seconds
 * @param {number} props.quiz.questionsCount - Number of questions
 * @param {number} props.quiz.submissionsCount - Number of submissions
 * @param {boolean} props.quiz.loading - Loading state
 * @param {Function} props.onStatusUpdate - Callback for status changes
 * @param {Function} props.onDelete - Callback for deletion
 * @returns {JSX.Element} Rendered list item
 */
const QuizListItem = ({ quiz, onStatusUpdate, onDelete }) => {
  const optionsRef = useRef();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  // Use custom hook for options menu
  useClickOutside(optionsRef, () => {
    if (isOptionsOpen) setIsOptionsOpen(false);
  });

  /**
   * Handles successful code copy action
   */
  const handleCodeCopy = () => {
    setCopied(true);
  };

  /**
   * Opens share modal and resets copy state
   * @param {React.MouseEvent} event - Click event
   */
  const handleModalOpen = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
    setCopied(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  /**
   * Navigates to submissions view
   * @param {React.MouseEvent} event - Click event
   */
  const handleSubmissionsClick = (event) => {
    event.preventDefault();
    window.location.href = `/quizzes/${quiz.quizId}/submissions`;
  };

  /**
   * Toggles quiz publish status
   * @param {React.MouseEvent} event - Click event
   */
  const handleStatusUpdate = (event) => {
    event.preventDefault();
    onStatusUpdate(
      quiz.quizId,
      quiz.status === 'draft' ? 'published' : 'draft'
    );
  };

  /**
   * Initiates quiz deletion
   * @param {React.MouseEvent} event - Click event
   */
  const handleDelete = (event) => {
    event.preventDefault();
    onDelete(quiz.quizId);
  };

  return quiz.loading ? (
    <CardSkeleton kreeda={quiz} />
  ) : (
    <>
      {quiz.code && (
        <Modal
          id={quiz.code}
          component={Code}
          componentProps={{
            link: `${playServer}?code=${quiz.code}`,
            copied,
            onCopy: handleCodeCopy,
          }}
          isOpen={isModalOpen}
          onClose={handleModalClose}
        />
      )}
      <Link className="activity-item" to={`/quizzes/${quiz.quizId}`}>
        <div className="activity-header">
          <h3>{quiz.name}</h3>
          <div ref={optionsRef} className="options-container">
            <button
              className="options-button"
              onClick={(event) => {
                event.preventDefault();
                setIsOptionsOpen(!isOptionsOpen);
              }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </button>
            <div className={`options-menu ${isOptionsOpen ? 'show' : ''}`}>
              <button
                className="btn--secondary"
                onClick={handleSubmissionsClick}
              >
                <FontAwesomeIcon icon={faClipboardCheck} />
                View Submissions
              </button>
              <button
                className="btn--secondary"
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <FontAwesomeIcon icon={faBoxArchive} />
                Archive
              </button>
              <button
                className="btn--delete"
                disabled={quiz.status !== 'draft'}
                onClick={handleDelete}
              >
                <FontAwesomeIcon icon={faTrash} />
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className="activity-meta">
          <span>{quiz.duration / 60} min </span>•
          <span>{quiz.questionsCount} question(s)</span>•
          <span>{quiz.submissionsCount} submission(s)</span>
        </div>
        <div className="activity-actions">
          <button
            className="btn--primary publish"
            onClick={handleStatusUpdate}
            disabled={quiz.questionsCount === 0}
          >
            <FontAwesomeIcon
              icon={quiz.status === 'draft' ? faCircleUp : faCircleDown}
            />
            {quiz.status === 'draft' ? 'Publish' : 'Unpublish'}
          </button>
          <button
            className="btn--secondary share"
            value={quiz.code}
            onClick={handleModalOpen}
            disabled={quiz.status === 'draft'}
          >
            <FontAwesomeIcon icon={faShareNodes} />
            Share
          </button>
        </div>
      </Link>
    </>
  );
};

export default QuizListItem;
