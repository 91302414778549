/**
 * @fileoverview Toast notification component for displaying error messages
 * Provides animated error notifications with auto-dismiss functionality
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { clearError } from '../../redux/reducers/quizzes.js';

/**
 * Toast notification component that displays error messages
 * Features:
 * - Animated entrance and exit
 * - Auto-dismissal after 5 seconds
 * - Progress indicator
 * - Manual close button
 *
 * @param {Object} props - Component props
 * @param {boolean} props.error - Whether an error is present
 * @param {string} props.errorDetail - Detailed error message to display
 * @param {Function} props.clearError - Redux action to clear the error state
 * @returns {JSX.Element} Rendered notification toast
 */
const NotificationToast = ({ error, errorDetail }) => {
  const [move, setMove] = useState('');

  useEffect(() => {
    if (error) {
      setMove('dropdown');
      setTimeout(() => {
        setMove('riseup');
        clearError();
      }, 5000);
    }
  }, [error]);

  return (
    <div className={`notification-toast__container ${move}`}>
      <div className={`notification-toast__content`}>
        <div className="notification-toast__message">
          {errorDetail}
          <button className={`notification-toast__close`}>x</button>
        </div>
        <div className="notification-toast__timer"></div>
      </div>
    </div>
  );
};

/**
 * Maps Redux state to component props
 * @param {Object} state - Redux state
 * @returns {Object} Props for component
 */
const mapStateToProps = (state) => {
  return {
    error: state.quizzes.error,
    errorDetail: state.quizzes.errorDetail,
  };
};

/**
 * Redux action creators to be bound to component props
 * @type {Object.<string, Function>}
 */
const mapDispatchToProps = {
  clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationToast);
