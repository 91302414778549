/**
 * @fileoverview Home page component
 * Main landing page with quick actions, trending content, and recent activity
 */

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faHashtag } from '@fortawesome/free-solid-svg-icons';

import Card from '../components/UI/Card';
import Modal from '../components/UI/Modal';
import CreateKreeda from '../components/CreateWizard/CreateKreeda';

/**
 * Home page component
 * Features:
 * - Welcome section with app introduction
 * - Quick action cards for creating activities
 * - Trending activities section
 * - Recent activity section
 * - Create activity modal integration
 *
 * @returns {JSX.Element} Rendered home page
 */
const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('');

  const quickActions = [
    {
      title: 'Create Quiz',
      description: 'Create engaging quizzes with multiple question types',
      icon: faQuestion,
      type: 'quiz',
    },
    {
      title: 'Create Crossword',
      description: 'Design interactive crossword puzzles',
      icon: faHashtag,
      type: 'crossword',
    },
    // {
    //   title: 'Create Survey',
    //   description: 'Gather feedback and insights',
    //   icon: faSquarePollVertical,
    //   type: 'survey',
    // },
  ];

  /**
   * Handles quick action card click
   * Opens the creation modal with the selected activity type
   *
   * @param {string} type - Activity type ('quiz' or 'crossword')
   */
  const handleQuickAction = (type) => {
    setSelectedType(type);
    setIsModalOpen(true);
  };

  /**
   * Handles modal close event
   * Resets the selected activity type and closes the modal
   */
  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedType('');
  };

  const trendingQuizzes = [
    {
      title: 'Mathematics Quiz',
      description: '10 questions • Created 2 days ago',
      plays: 150,
    },
    {
      title: 'Science Trivia',
      description: '15 questions • Created 3 days ago',
      plays: 120,
    },
  ];

  return (
    <div className="home-container">
      {/* Welcome Section */}
      <section className="welcome-section">
        <h1>Welcome to Gyaan Kreeda</h1>
        <p>Create engaging quizzes, crosswords, and surveys</p>
      </section>

      {/* Quick Actions */}
      <section className="quick-actions">
        <h2>Quick Actions</h2>
        <div className="quick-actions-grid">
          {quickActions.map((action, index) => (
            <div
              key={index}
              className="quick-action-card"
              onClick={() => handleQuickAction(action.type)}
            >
              <div className="quick-action-icon">
                <FontAwesomeIcon icon={action.icon} />
              </div>
              <h3>{action.title}</h3>
              <p>{action.description}</p>
            </div>
          ))}
        </div>
      </section>

      <Modal
        id="create-kreeda"
        component={CreateKreeda}
        componentProps={{
          initialType: selectedType,
          skipTypeSelection: true,
        }}
        isOpen={isModalOpen}
        onClose={handleModalClose}
      />

      {/* Trending Section */}
      <section className="trending-section">
        <h2>Trending Now</h2>
        <div className="trending-grid">
          {trendingQuizzes.map((quiz, index) => (
            <Card key={index} className="trending-card">
              <div className="trending-card-content">
                <h3>{quiz.title}</h3>
                <p>{quiz.description}</p>
                <div className="trending-card-stats">
                  <span>👥 {quiz.plays} plays</span>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </section>

      {/* Recent Activity */}
      <section className="recent-activity">
        <h2>Recent Activity</h2>
        <div className="activity-list">
          <Card className="activity-card">
            <p>No recent activity</p>
          </Card>
        </div>
      </section>
    </div>
  );
};

export default Home;
