/**
 * @fileoverview Crossword listing page
 * Container component for displaying all crosswords
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CrosswordList from '../../components/Crossword/CrosswordList';

import { getCrosswords } from '../../redux/actions/crosswords.js';
import { clearError } from '../../redux/reducers/crosswords.js';

/**
 * Crossword listing page component
 * Features:
 * - Automatic data fetching on mount
 * - Error clearing on unmount
 * - Renders CrosswordList component
 *
 * @param {Object} props - Component props
 * @param {Array<Object>} props.crosswords - Array of crossword objects
 * @param {Function} props.getCrosswords - Action to fetch all crosswords
 * @param {Function} props.clearError - Action to clear error state
 * @returns {JSX.Element} Rendered crossword list
 */
const ListCrosswords = ({ crosswords, getCrosswords, clearError }) => {
  /**
   * Fetches crosswords on mount if not already loaded
   * Clears error state on unmount
   */
  useEffect(() => {
    if (crosswords.length <= 1) getCrosswords();
    return () => {
      clearError();
    };
  }, []);
  return <CrosswordList />;
};

/**
 * Maps Redux state to component props
 * @param {Object} state - Redux state
 * @returns {Object} Props for component
 */
const mapStateToProps = (state) => ({
  crosswords: state.crosswords.crosswords,
  loading: state.crosswords.loading,
  error: state.crosswords.error,
  errorDetail: state.crosswords.errorDetail,
});

/**
 * Redux action creators to be bound to component props
 * @type {Object.<string, Function>}
 */
const mapDispatchToProps = {
  getCrosswords,
  clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCrosswords);
