/**
 * @fileoverview Coming soon placeholder page
 * Displays a simple message for features that are under development
 */

import React from 'react';

/**
 * Coming soon page component
 * Renders a placeholder message for features that are not yet implemented
 *
 * @returns {JSX.Element} Rendered coming soon message
 */
const ComingSoon = () => {
  return (
    <div style={{ color: 'grey' }}>
      <h2>Coming Soon...</h2>
    </div>
  );
};

export default ComingSoon;
