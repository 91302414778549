/**
 * @fileoverview Custom React hooks for UI interactions
 * Provides reusable hooks for common UI patterns and behaviors
 */

import { useEffect } from 'react';

/**
 * Hook to detect clicks outside a referenced element
 * Commonly used for closing dropdowns, modals, or popups when clicking outside
 *
 * @param {React.RefObject} ref - React ref object attached to the element to monitor
 * @param {Function} handler - Callback function to execute when a click outside is detected
 * @example
 * const modalRef = useRef();
 * useClickOutside(modalRef, () => setIsOpen(false));
 */
export const useClickOutside = (ref, handler) => {
  useEffect(() => {
    /**
     * Event listener callback that checks if a click occurred outside the referenced element
     * @param {MouseEvent} event - The mouse event object
     */
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
