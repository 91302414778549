/**
 * @fileoverview Service for managing crossword-related operations
 * Handles CRUD operations for crosswords, word management, and submission handling
 */

import { RESOURCENOTFOUND, FIELDMISSING } from '../utils/errors.js';
import { configService } from './config.service.js';

const { prefixUrl } = await configService.appConfig;

/**
 * Fetches all crosswords for the authenticated user
 * @returns {Promise<Array<Crossword>>} Array of crossword objects
 * @throws {Error} If fetching fails or session is invalid
 */
async function getCrosswords() {
  const res = await fetch(`${prefixUrl}/crosswords`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Unable to fetch crosswords. Please try after some time.'
          );
        } else {
          throw new Error(
            'Unable to fetch crosswords. Please try after some time.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }
  const responseBody = await res.json();
  return responseBody.Data.Crosswords;
}

/**
 * Creates a new crossword
 * @param {Object} data - Crossword data to create
 * @param {string} data.name - Name of the crossword
 * @param {Array} [data.words] - Initial words for the crossword
 * @returns {Promise<Crossword>} Created crossword object
 * @throws {Error} If creation fails or required fields are missing
 */
async function createCrossword(data) {
  const res = await fetch(`${prefixUrl}/crosswords`, {
    method: 'POST',
    body: JSON.stringify({ Data: { ...data } }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === FIELDMISSING) {
          throw new Error(
            'A required field is missing. Please contact administrator.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return responseBody.Data.Crossword;
}

/**
 * Retrieves detailed information for a specific crossword
 * @param {string} crosswordId - ID of the crossword to fetch
 * @returns {Promise<Crossword>} Crossword details
 * @throws {Error} If crossword doesn't exist or access is denied
 */
async function getCrosswordDetails(crosswordId) {
  const res = await fetch(`${prefixUrl}/crosswords/${crosswordId}`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error('The crossword you requested does not exist.');
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return responseBody.Data.Crossword;
}

/**
 * Updates an existing crossword's metadata
 * @param {string} crosswordId - ID of the crossword to update
 * @param {Object} data - Updated crossword data
 * @returns {Promise<Crossword>} Updated crossword object
 * @throws {Error} If crossword doesn't exist or update fails
 */
async function updateCrossword(crosswordId, data) {
  const res = await fetch(`${prefixUrl}/crosswords/${crosswordId}`, {
    method: 'PATCH',
    body: JSON.stringify({ Data: { ...data } }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to update crossword. The crossword you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return { crossword: { ...responseBody.Data.Crossword } };
}

/**
 * Deletes a crossword
 * @param {string} crosswordId - ID of the crossword to delete
 * @returns {Promise<{crosswordId: string}>} Deleted crossword ID
 * @throws {Error} If crossword doesn't exist or deletion fails
 */
async function deleteCrossword(crosswordId) {
  const res = await fetch(`${prefixUrl}/crosswords/${crosswordId}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to delete crossword. The crossword you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  return { crosswordId };
}

/**
 * Updates the status of a crossword (e.g., publish/unpublish)
 * @param {string} crosswordId - ID of the crossword
 * @param {string} status - New status to set
 * @returns {Promise<Crossword>} Updated crossword object
 * @throws {Error} If status update fails
 */
async function updateCrosswordStatus(crosswordId, status) {
  const res = await fetch(`${prefixUrl}/crosswords/${crosswordId}/status`, {
    method: 'PUT',
    body: JSON.stringify({ Data: { status } }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to publish crossword. The crossword you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return { crossword: { ...responseBody.Data.Crossword, crosswordId } };
}

/**
 * Updates the words in a crossword
 * @param {string} crosswordId - ID of the crossword
 * @param {Array<Object>} data - Array of word objects to update
 * @returns {Promise<Crossword>} Updated words array
 * @throws {Error} If word update fails
 */
async function updateWords(crosswordId, data) {
  const res = await fetch(`${prefixUrl}/crosswords/${crosswordId}/words`, {
    method: 'PUT',
    body: JSON.stringify({ Data: [...data] }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Failed to add word. The crossword you requested does not exist.'
          );
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return { crosswordId, words: [...responseBody.Data.Words] };
}

/**
 * Fetches all submissions for a specific crossword
 * @param {string} crosswordId - ID of the crossword
 * @returns {Promise<Array>} Array of submission objects
 * @throws {Error} If fetching submissions fails
 */
async function getSubmissions(crosswordId) {
  const res = await fetch(
    `${prefixUrl}/crosswords/${crosswordId}/submissions`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error('The crossword you requested does not exist.');
        }
      case 401:
        throw new Error('session has expired. Please login again.');
      case 403:
        throw new Error('access denied.');
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return responseBody.Data.Submissions;
}

const crosswordService = {
  getCrosswords,
  createCrossword,
  getCrosswordDetails,
  updateCrossword,
  deleteCrossword,
  updateCrosswordStatus,
  updateWords,
  getSubmissions,
};

export default crosswordService;
