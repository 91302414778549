/**
 * @fileoverview First step of activity creation wizard
 * Provides activity type selection interface with visual indicators
 */

import React from 'react';

import imgQuiz from '../../assets/images/quiz.png';
import imgCrossword from '../../assets/images/crossword.png';

/**
 * Activity type selection component in creation wizard
 * Features:
 * - Visual selection cards with images
 * - Quiz and Crossword type options
 * - Automatic step advancement
 * - State management for selected type
 *
 * @param {Object} props - Component props
 * @param {number} props.currentStep - Current step in the wizard
 * @param {Function} props.setKreeda - Function to update activity state
 * @param {Function} props.setCurrentStep - Function to advance wizard step
 * @returns {JSX.Element} Rendered type selection interface
 */
const SelectType = ({ currentStep, setKreeda, setCurrentStep }) => {
  return (
    <>
      <h3>Select Type</h3>
      <div className="type-items">
        <div
          className="type-item"
          /**
           * Updates activity type to quiz and advances wizard
           */
          onClick={() => {
            setKreeda((kreeda) => ({
              ...kreeda,
              type: 'quiz',
            }));
            setCurrentStep(currentStep + 1);
          }}
        >
          <div className="type-item__heading">
            <h4>QUIZ</h4>
          </div>
          <div className="type-item__image">
            <img src={imgQuiz} />
          </div>
        </div>
        <div
          className="type-item"
          /**
           * Updates activity type to crossword and advances wizard
           */
          onClick={() => {
            setKreeda((kreeda) => ({
              ...kreeda,
              type: 'crossword',
            }));
            setCurrentStep(currentStep + 1);
          }}
        >
          <div className="type-item__heading">
            <h4>CROSSWORD</h4>
          </div>
          <div className="type-item__image">
            <img src={imgCrossword} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectType;
