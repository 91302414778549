/**
 * @fileoverview Skeleton loading component for card items
 * Provides a loading placeholder that matches the structure of card components
 */

import React from 'react';

/**
 * Card skeleton component for loading states
 * Displays a placeholder with animated gradients that match the card layout:
 * - Header with title placeholder
 * - Multiple text line placeholders
 * - Action button placeholders
 *
 * @param {Object} props - Component props
 * @param {Object} props.kreeda - Kreeda (activity) object
 * @param {string} props.kreeda.status - Activity status affecting button states
 * @returns {JSX.Element} Rendered skeleton loader
 */
const CardSkeleton = ({ kreeda }) => {
  return (
    <div className="card-item">
      <div className="card-item__head">
        <div className="card-item__head__heading">
          <div className="h3__skeleton"></div>
          <div className="text__skeleton"></div>
          <div className="text__skeleton"></div>
        </div>
      </div>
      <div className="card-item__actions">
        <button className="btn__skeleton--primary"></button>
        <button
          className="btn__skeleton--secondary"
          disabled={kreeda.status === 'draft'}
        ></button>
      </div>
    </div>
  );
};

export default CardSkeleton;
