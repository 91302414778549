/**
 * @fileoverview Error code constants used throughout the application
 * Defines standardized error codes for common error scenarios
 */

/**
 * Error code for when a requested resource cannot be found
 * Used in API responses for 404-like scenarios
 * @type {string}
 */
export const RESOURCENOTFOUND = 'GK.Resource.NotFound';
/**
 * Error code for when a required field is missing in a request
 * Used in API responses for validation failures
 * @type {string}
 */
export const FIELDMISSING = 'GK.Field.Missing';
/**
 * Error code for when a user is not authenticated
 * Used in API responses for unauthorized access attempts
 * @type {string}
 */
export const ANAUTHENTICATED = '';
/**
 * Error code for when a user's session has expired
 * Used in API responses for expired authentication
 * @type {string}
 */
export const SESSIONEXPIRED = '';
