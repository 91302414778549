/**
 * @fileoverview Redux actions for crossword management
 * Contains async thunks for CRUD operations on crosswords and their words
 */

import { createAsyncThunk } from '@reduxjs/toolkit';

import crosswordService from '../../services/crossword.service.js';

/**
 * Fetches all crosswords
 * @type {import('@reduxjs/toolkit').AsyncThunk<{crosswords: Array<Crossword>}, void, {}>}
 */
export const getCrosswords = createAsyncThunk(
  'crosswords/get',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const crosswords = await crosswordService.getCrosswords();
      return fulfillWithValue({ crosswords: [...crosswords] });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Creates a new crossword
 * @type {import('@reduxjs/toolkit').AsyncThunk<{crossword: Crossword}, Object, {}>}
 */
export const createCrossword = createAsyncThunk(
  'crosswords/create',
  async (crossword, { fulfillWithValue, rejectWithValue }) => {
    try {
      const createdCrossword =
        await crosswordService.createCrossword(crossword);
      return fulfillWithValue({ crossword: { ...createdCrossword } });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Fetches a single crossword by ID
 * @type {import('@reduxjs/toolkit').AsyncThunk<{crossword: Crossword}, string, {}>}
 */
export const getCrossword = createAsyncThunk(
  'crosswords/getOne',
  async (crosswordId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const crossword = await crosswordService.getCrosswordDetails(crosswordId);
      return fulfillWithValue({ crossword });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Updates an existing crossword
 * @type {import('@reduxjs/toolkit').AsyncThunk<{crossword: Crossword}, {crosswordId: string, data: Object}, {}>}
 */
export const updateCrossword = createAsyncThunk(
  'crosswords/update',
  async ({ crosswordId, data }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const crossword = await crosswordService.updateCrossword(
        crosswordId,
        data
      );
      return fulfillWithValue(crossword);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Deletes a crossword
 * @type {import('@reduxjs/toolkit').AsyncThunk<{crosswordId: string}, string, {}>}
 */
export const deleteCrossword = createAsyncThunk(
  'crosswords/delete',
  async (crosswordId, { fulfillWithValue, rejectWithValue }) => {
    try {
      await crosswordService.deleteCrossword(crosswordId);
      return fulfillWithValue({ crosswordId });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Updates the status of a crossword (e.g., publish/unpublish)
 * @type {import('@reduxjs/toolkit').AsyncThunk<{crossword: Crossword}, {crosswordId: string, status: string}, {}>}
 */
export const updateCrosswordStatus = createAsyncThunk(
  'crosswords/publish',
  async ({ crosswordId, status }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const crossword = await crosswordService.updateCrosswordStatus(
        crosswordId,
        status
      );
      return fulfillWithValue(crossword);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Updates the words in a crossword
 * @type {import('@reduxjs/toolkit').AsyncThunk<{crosswordId: string, words: Array<Word>}, {crosswordId: string, words: Array}, {}>}
 */
export const updateWords = createAsyncThunk(
  'crosswords/updateWords',
  async ({ crosswordId, words }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const data = await crosswordService.updateWords(crosswordId, words);
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Fetches all submissions for a crossword
 * @type {import('@reduxjs/toolkit').AsyncThunk<{crosswordId: string, submissions: Array<Submission>}, string, {}>}
 */
export const getSubmissions = createAsyncThunk(
  'crosswords/getSubmissions',
  async (crosswordId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const submissions = await crosswordService.getSubmissions(crosswordId);
      return fulfillWithValue({ crosswordId, submissions });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
