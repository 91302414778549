/**
 * @fileoverview Individual crossword item component for list display
 * Provides detailed view and actions for a single crossword
 */

import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxArchive,
  faCircleDown,
  faCircleUp,
  faClipboardCheck,
  faShareNodes,
  faTrash,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';

import Modal from '../UI/Modal';
import Code from '../UI/Code.jsx';
import CardSkeleton from '../UI/CardSkeleton';

import { configService } from '../../services/config.service.js';
import { useClickOutside } from '../../hooks/ui.js';

const { playServer } = await configService.appConfig;

/**
 * Crossword list item component with interactive features
 * Features:
 * - Metadata display (duration, word count, submissions)
 * - Status management (publish/unpublish)
 * - Share functionality with code copying
 * - Options menu (submissions, archive, delete)
 * - Loading state handling
 *
 * @param {Object} props - Component props
 * @param {Object} props.crossword - Crossword data
 * @param {string} props.crossword.crosswordId - Unique identifier
 * @param {string} props.crossword.name - Crossword name
 * @param {string} props.crossword.status - Current status
 * @param {string} props.crossword.code - Share code
 * @param {number} props.crossword.duration - Duration in seconds
 * @param {number} props.crossword.wordsCount - Number of words
 * @param {number} props.crossword.submissionsCount - Number of submissions
 * @param {boolean} props.crossword.loading - Loading state
 * @param {Function} props.onStatusUpdate - Callback for status changes
 * @param {Function} props.onDelete - Callback for deletion
 * @returns {JSX.Element} Rendered list item
 */
const CrosswordListItem = ({ crossword, onStatusUpdate, onDelete }) => {
  const optionsRef = useRef();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  // Use custom hook for options menu
  useClickOutside(optionsRef, () => {
    if (isOptionsOpen) setIsOptionsOpen(false);
  });

  /**
   * Handles successful code copy action
   */
  const handleCodeCopy = () => {
    setCopied(true);
  };

  /**
   * Opens share modal and resets copy state
   * @param {React.MouseEvent} event - Click event
   */
  const handleModalOpen = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
    setCopied(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  /**
   * Navigates to submissions view
   * @param {React.MouseEvent} event - Click event
   */
  const handleSubmissionsClick = (event) => {
    event.preventDefault();
    window.location.href = `/crosswords/${crossword.crosswordId}/submissions`;
  };

  /**
   * Toggles crossword publish status
   * @param {React.MouseEvent} event - Click event
   */
  const handleStatusUpdate = (event) => {
    event.preventDefault();
    onStatusUpdate(
      crossword.crosswordId,
      crossword.status === 'draft' ? 'published' : 'draft'
    );
  };

  /**
   * Initiates crossword deletion
   * @param {React.MouseEvent} event - Click event
   */
  const handleDelete = (event) => {
    event.preventDefault();
    onDelete(crossword.crosswordId);
  };

  return crossword.loading ? (
    <CardSkeleton kreeda={crossword} />
  ) : (
    <>
      {crossword.code && (
        <Modal
          id={crossword.code}
          component={Code}
          componentProps={{
            link: `${playServer}?code=${crossword.code}`,
            copied,
            onCopy: handleCodeCopy,
          }}
          isOpen={isModalOpen}
          onClose={handleModalClose}
        />
      )}{' '}
      <Link
        className="activity-item"
        to={`/crosswords/${crossword.crosswordId}`}
      >
        <div className="activity-header">
          <h3>{crossword.name}</h3>
          <div ref={optionsRef} className="options-container">
            <button
              className="options-button"
              onClick={(event) => {
                event.preventDefault();
                setIsOptionsOpen(!isOptionsOpen);
              }}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </button>
            <div className={`options-menu ${isOptionsOpen ? 'show' : ''}`}>
              <button onClick={handleSubmissionsClick}>
                <FontAwesomeIcon icon={faClipboardCheck} />
                View Submissions
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <FontAwesomeIcon icon={faBoxArchive} />
                Archive
              </button>
              <button
                className="delete-button"
                disabled={crossword.status !== 'draft'}
                onClick={handleDelete}
              >
                <FontAwesomeIcon icon={faTrash} />
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className="activity-meta">
          <span>{crossword.duration / 60} min</span>•
          <span>{crossword.wordsCount} word(s)</span>•
          <span>{crossword.submissionsCount} submission(s)</span>
        </div>
        <div className="activity-actions">
          <button
            className="btn--primary publish"
            onClick={handleStatusUpdate}
            disabled={crossword.wordsCount === 0}
          >
            <FontAwesomeIcon
              icon={crossword.status === 'draft' ? faCircleUp : faCircleDown}
            />
            {crossword.status === 'draft' ? 'Publish' : 'Unpublish'}
          </button>
          <button
            className="btn--secondary share"
            value={crossword.code}
            onClick={handleModalOpen}
            disabled={crossword.status === 'draft'}
          >
            <FontAwesomeIcon icon={faShareNodes} />
            Share
          </button>
        </div>
      </Link>
    </>
  );
};

export default CrosswordListItem;
