/**
 * @fileoverview Redux selector for determining global loading state
 * Combines loading states from quiz and crossword features
 */

/**
 * Selector that determines if any part of the application is loading
 * @param {boolean} quizLoading - Loading state for quiz-related operations
 * @param {boolean} crosswordLoading - Loading state for crossword-related operations
 * @returns {boolean} True if either quiz or crossword operations are loading
 */
export default (quizLoading, crosswordLoading) => {
  return quizLoading || crosswordLoading;
};
